<template>
    <div>
        <label for="" class="vs-input--label"
            ><span v-if="selected">Search Coupons</span></label
        >

        <v-select
            v-model="selected"
            :options="coupons"
            @search="fetchCoupons"
            placeholder="Search Coupons"
        ></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
    name: 'CouponSearch',
    components: {
        vSelect,
    },
    props: ['coupon'],
    watch: {
        selected(value) {
            this.$emit('selectedCoupon', value)
        },
        coupon(value) {
            this.selected = value
        },
    },
    data() {
        return {
            coupons: [],
            selected: '',
        }
    },
    methods: {
        fetchCoupons(search, loading) {
            if (search) {
                http.get('coupons_widget/' + search)
                    .then((response) => {
                        this.coupons = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                this.coupons = []
            }
        },
    },
}
</script>
