<template>
    <statistics-card-line
        hideChart
        class="mb-base"
        icon="MailIcon"
        :statistic="willsUnRead"
        :statisticTitle="subTitle"
        :color="colour"
        :to="path"
    />
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

export default {
    name: 'EmailCard',
    components: {
        StatisticsCardLine,
    },
    props: ['user', 'emails'],
    data() {
        return {
            show: {
                emailComposeModal: false,
            },
        }
    },
    computed: {
        willsUnRead() {
            if (this.emails) return this.emails.unread + ' Unread'
            return ''
        },
        subTitle() {
            if (this.emails)
                return (
                    this.emails.received +
                    ' Received ' +
                    this.emails.sent +
                    ' Sent '
                )
            return ''
        },
        colour() {
            if (this.emails.unread) return 'secondary'
            return 'primary'
        },
        path() {
            if (this.user) {
                return '/email/' + this.user.id + '/' + this.user.email
            }
            return ''
        },
    },
}
</script>
