var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":_vm.user.email,"edit-action":!_vm.locked,"collapse-action":"","title-color":"#fff","subtitle-color":"#fff","content-color":"#fff","card-background":"rgba(var(--vs-primary),0.65)"},on:{"edit":_vm.edit}},[_c('ul',{staticClass:"friend-suggesions-list"},[(_vm.userAccount && _vm.userAccount.change_password)?_c('vs-alert',{staticClass:"mb-4"},[_vm._v("Password Not Set")]):_vm._e(),(_vm.userAccount && _vm.userAccount.temp_account)?_c('vs-alert',{staticClass:"mb-4"},[_vm._v("Temporary Broker Account by "+_vm._s(_vm.userAccount.broker))]):_vm._e(),_c('li',{staticClass:"friend-suggestion flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-primary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-user"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.user.details.full_name))]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.user.details.marital_status))])]),_c('div',{staticClass:"ml-auto cursor-pointer self-center"},[(!_vm.hideUserLink)?_c('vs-button',{attrs:{"radius":"","type":"flat","icon-pack":"feather","icon":"icon-chevron-right","color":"#fff"},on:{"click":function($event){return _vm.$router.push({
                            name: 'user',
                            params: { id: _vm.user.details.user },
                        })}}}):_vm._e()],1)]),(_vm.user.details.marital_status !== 'Single')?_c('li',[_c('div',{staticClass:"friend-suggestion flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                            background: `rgba(var(--vs-primary),.15)`,
                        }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-users"}})],1),_c('div',{staticClass:"leading-tight flex justify-between w-full"},[_c('div',{staticClass:"w-full"},[(_vm.user.details.partner)?_c('p',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.user.details.partner.full_name)+" ")]):_c('p',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.user.details.marital_status === 'Married' ? 'Spouse' : 'Partner')+" ")]),(!_vm.user.details.partner)?_c('p',{staticClass:"text-xs"},[_vm._v(" Account not created ")]):_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.user.details.marital_status === 'Married' ? 'Spouse' : 'Partner')+" ")]),(_vm.show.linkPartner)?_c('div',{staticClass:"flex items-end"},[_c('UserSelect',{staticClass:"w-3/4 mr-2 mt-3",attrs:{"white":true},on:{"selectedUser":_vm.setPartner}}),_c('vs-button',{attrs:{"radius":"","color":"#fff","type":"flat","icon-pack":"feather","icon":"icon-save"},on:{"click":_vm.savePartner}})],1):_vm._e()]),(!_vm.user.details.partner)?_c('div',{staticClass:"ml-auto"},[_c('vx-tooltip',{attrs:{"text":"Link Partner"}},[_c('vs-button',{attrs:{"radius":"","color":"primary","type":"flat","icon-pack":"feather","icon":"icon-link","text-color":"#fff"},on:{"click":function($event){_vm.show.linkPartner = !_vm.show.linkPartner}}})],1)],1):_vm._e()]),(_vm.user.details.partner)?_c('div',{staticClass:"ml-auto cursor-pointer self-center"},[_c('vs-button',{attrs:{"radius":"","type":"flat","icon-pack":"feather","icon":"icon-chevron-right","color":"#fff"},on:{"click":function($event){return _vm.$router.push({
                                name: 'user',
                                params: {
                                    id: _vm.user.details.partner.user_id,
                                },
                            })}}})],1):_vm._e()])]):_vm._e(),_c('li',{staticClass:"friend-suggestion flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-primary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-home"}})],1),_c('div',{staticClass:"flex justify-between w-full"},[_c('div',{staticClass:"leading-tight"},[(_vm.user.details.country)?_c('div',{staticClass:"font-medium"},[_c('p',[_vm._v(_vm._s(_vm.user.details.country))])]):_vm._e(),(_vm.user.details.address_line_1)?_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.user.details.address_line_1)+" "),_c('br')]):_vm._e(),(_vm.user.details.address_line_2)?_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.user.details.address_line_2)+" "),_c('br')]):_vm._e(),(_vm.user.details.post_town)?_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.user.details.post_town)+" "),_c('br')]):_vm._e(),(_vm.user.details.post_code)?_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.user.details.post_code))]):_vm._e()]),_c('div',[(_vm.user.details.moving)?_c('vs-chip',{attrs:{"color":"primary"}},[_vm._v("Currently Moving Home")]):_vm._e()],1)])]),_c('li',{staticClass:"friend-suggestion flex items-center"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-primary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-phone"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.user.details.phone))]),_c('p',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.user.email))])])])],1),_c('PersonalDetailsModal',{attrs:{"personalDetails":_vm.user.details,"show":_vm.show.detailsModal},on:{"close":_vm.closePersonalDetailsModal,"changed":function($event){return _vm.$emit('changed')},"newData":function($event){return _vm.$emit('newData', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }