<template>
    <SideBarModal :title="personName" :show="show" @close="$emit('close')">
        <template v-if="person" v-slot:content>
            <vs-collapse accordion>
                <div class="vx-row mb-2">
                    <div class="vx-col w-full">
                        <vs-input
                            class="w-full"
                            :danger="!person.full_name"
                            label-placeholder="Full Name"
                            v-model="person.full_name"
                        />
                    </div>
                </div>

                <div class="vx-row mb-2">
                    <div class="vx-col w-full">
                        <vs-input
                            class="w-full"
                            type="text"
                            :danger="!person.relationship"
                            label-placeholder="Relationship"
                            v-model="person.relationship"
                        />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-select
                            class="w-full"
                            label="Gender"
                            v-model="person.gender"
                        >
                            <vs-select-item value="Male" text="Male" />
                            <vs-select-item value="Female" text="Female" />
                        </vs-select>
                    </div>
                </div>

                <vs-collapse-item>
                    <div slot="header">Details</div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-1/2">
                            <vs-checkbox v-model="person.partner"
                                >Partner</vs-checkbox
                            >
                        </div>
                        <div class="vx-col w-1/2">
                            <vs-checkbox v-model="person.partner_ex"
                                >Ex Partner</vs-checkbox
                            >
                        </div>
                    </div>

                    <div class="vx-row mb-6">
                        <div class="vx-col w-1/3">
                            <vs-input
                                class="w-full"
                                type="number"
                                label-placeholder="Dob day"
                                v-model="person.dob_day"
                            />
                        </div>
                        <div class="vx-col w-1/3">
                            <vs-input
                                class="w-full"
                                type="number"
                                label-placeholder="Dob month"
                                v-model="person.dob_month"
                            />
                        </div>
                        <div class="vx-col w-1/3">
                            <vs-input
                                class="w-full"
                                type="number"
                                label-placeholder="Dob year"
                                v-model="person.dob_year"
                            />
                        </div>
                    </div>

                    <div class="vx-row mb-6">
                        <div class="vx-col w-1/2">
                            <vs-checkbox v-model="person.child"
                                >Child</vs-checkbox
                            >
                        </div>
                        <div class="vx-col w-1/2">
                            <vs-checkbox v-model="person.child_step"
                                >Step Child</vs-checkbox
                            >
                        </div>
                    </div>

                    <div class="vx-row mb-6">
                        <div class="vx-col w-1/2">
                            <vs-checkbox v-model="person.under18"
                                >Under 18</vs-checkbox
                            >
                        </div>
                        <div class="vx-col w-1/2">
                            <vs-checkbox v-model="person.under25"
                                >Under 25</vs-checkbox
                            >
                        </div>
                    </div>
                </vs-collapse-item>
                <vs-collapse-item>
                    <div slot="header">Contact</div>
                    <div class="vx-row mb-2">
                        <div class="vx-col w-full">
                            <vs-input
                                class="w-full"
                                type="text"
                                label-placeholder="Address"
                                v-model="person.address"
                            />
                        </div>
                    </div>

                    <div class="vx-row mb-2">
                        <div class="vx-col w-full">
                            <vs-input
                                class="w-full"
                                type="email"
                                label-placeholder="Email"
                                v-model="person.email"
                            />
                        </div>
                    </div>

                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-input
                                class="w-full"
                                type="text"
                                label-placeholder="Phone number"
                                v-model="person.phone"
                            />
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.share"
                                >Share with
                                Executors/Trustees/Guardians</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.share_details_sent"
                                >Share details already sent</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.share_broker"
                                >Share with Broker</vs-checkbox
                            >
                        </div>
                    </div>
                </vs-collapse-item>
                <vs-collapse-item>
                    <div slot="header">Will</div>

                    <h6 class="mb-6">Executor</h6>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.executor_sole_primary"
                                >Sole</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.executor_primary"
                                >Primary</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.executor_secondary"
                                >Secondary</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.executor_backup"
                                >Backup</vs-checkbox
                            >
                        </div>
                    </div>

                    <vs-divider></vs-divider>

                    <h6 class="mb-6">Trustee</h6>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.trustee_sole_primary"
                                >Sole</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.trustee_primary"
                                >Primary</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.trustee_secondary"
                                >Secondary</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.trustee_backup"
                                >Backup</vs-checkbox
                            >
                        </div>
                    </div>

                    <vs-divider></vs-divider>

                    <h6 class="mb-6">Guardian</h6>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.guardian_primary"
                                >Primary</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.guardian_secondary"
                                >Secondary</vs-checkbox
                            >
                        </div>
                    </div>

                    <vs-divider></vs-divider>

                    <h6 class="mb-6">Pets</h6>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.pets_sole_primary"
                                >Sole</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="person.pets_primary"
                                >Primary</vs-checkbox
                            >
                        </div>
                    </div>
                </vs-collapse-item>
                <vs-collapse-item>
                    <div slot="header">Distribution</div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-checkbox
                                v-model="person.distribution_sole_primary"
                                >Sole</vs-checkbox
                            >
                        </div>
                    </div>
                    <vs-divider></vs-divider>

                    <div class="flex mb-3">
                        <div class="w-1/2 object-left">
                            <vs-checkbox v-model="person.distribution_primary"
                                >Primary</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="flex flex-row mb-3">
                        <div class="w-2/3">
                            <div class="vx-col w-2/3">
                                <vs-input
                                    label-placeholder="Percentage"
                                    v-model="
                                        person.distribution_primary_percentage
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="rounded-lg p-3 bg-grey-light">
                        <h6 class="mb-3">Primary backup option</h6>
                        <div class="flex flex-row mb-3">
                            <div class="w-full">
                                <vs-checkbox
                                    v-model="
                                        person.distribution_backup_children
                                    "
                                    >Children</vs-checkbox
                                >
                            </div>
                        </div>
                        <div class="flex flex-row mb-3">
                            <div class="w-full">
                                <vs-checkbox
                                    v-model="
                                        person.distribution_backup_other_beneficiaries
                                    "
                                    >Other Beneficairies
                                </vs-checkbox>
                            </div>
                        </div>

                        <div class="flex flex-row mb-3">
                            <div class="w-full">
                                <vs-input
                                    label-placeholder="Group"
                                    v-model="person.distribution_backup_group"
                                />
                            </div>
                        </div>
                        <div class="flex flex-row mb-3">
                            <div class="w-full">
                                <vs-select
                                    label="Person"
                                    v-model="person.distribution_backup_person"
                                >
                                    <vs-select-item
                                        :value="person.id"
                                        :text="person.full_name"
                                        v-for="(person,
                                        index) in alternativePeople"
                                        :key="index"
                                    />
                                </vs-select>
                            </div>
                        </div>
                    </div>

                    <vs-divider></vs-divider>

                    <div class="vx-row mb-6">
                        <div class="vx-col w-1/2">
                            <vs-checkbox v-model="person.distribution_backup"
                                >Backup</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <div class="w-2/3">
                            <vs-input
                                class="w-full"
                                label-placeholder="Percentage"
                                v-model="person.distribution_backup_percentage"
                            />
                        </div>
                    </div>
                </vs-collapse-item>
            </vs-collapse>
        </template>
        <template v-slot:buttons>
            <vs-button
                :disabled="!validateForm"
                class="mr-6"
                @click="personSave"
                >Save
            </vs-button>
            <vs-button type="border" class="mr-6" @click="$emit('close')"
                >Cancel
            </vs-button>
            <vs-button
                v-if="personId"
                type="border"
                color="danger"
                iconPack="feather"
                icon="icon-trash"
                @click="deletePersonConfirm"
            >
            </vs-button>
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'
import { TextFormat } from '@/mixins/TextFormat'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'PersonModal',
    components: {
        SideBarModal,
    },
    props: ['personId', 'show', 'people', 'userId'],
    watch: {
        personId: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.personFetch(value)
                }
            },
        },
    },
    data() {
        return {
            person: {
                full_name: null,
                relationship: null,
                gender: 'Male',
                partner: false,
                partner_ex: false,
                dob_day: null,
                dob_month: null,
                dob_year: null,
                child: false,
                child_step: false,
                under18: false,
                under21: false,
                address: null,
                email: null,
                phone: null,
                share: false,
                share_details_sent: false,
                executor_sole_primary: false,
                executor_primary: false,
                executor_secondary: false,
                executor_backup: false,
                trustee_sole_primary: false,
                trustee_primary: false,
                trustee_secondary: false,
                trustee_backup: false,
                guardian_primary: false,
                guardian_secondary: false,
                pets_sole_primary: false,
                pets_primary: false,
                distribution_sole_primary: false,
                distribution_primary: false,
                distribution_primary_percentage: 0,
                distribution_backup_children: false,
                distribution_backup_other_beneficiaries: false,
                distribution_backup: false,
                distribution_backup_percentage: 0,
            },
        }
    },
    computed: {
        validateForm() {
            if (
                this.person.full_name &&
                this.person.relationship &&
                this.person.gender
            )
                return true
            return false
        },
        personName() {
            if (this.personId) return 'Edit Person'
            return 'Create Person'
        },
        alternativePeople() {
            return this.people.filter((person) => person.id !== this.personId)
        },
    },
    methods: {
        personFetch(personId) {
            http.get('person_detail', { params: { id: personId } })
                .then((response) => {
                    this.person = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        deletePersonConfirm() {
            console.log('confirm delete person')
            this.$emit('close')
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete Person`,
                text: `Remove ${this.person.full_name}`,
                accept: this.deletePerson,
                acceptText: 'Delete',
            })
        },
        deletePerson(personId) {
            console.log('delete person', this.person)
            if (this.person.id) {
                console.log('there is a person id')

                http.delete('person_detail', { params: { id: this.person.id } })
                    .then((response) => {
                        console.log(response.data)
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Person Removed',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$vs.notify({
                            text: error.response.data,
                            color: 'danger',
                        })
                    })
            }
        },
        personSave() {
            if (this.personId) {
                http.patch('person_detail', this.person)
                    .then((response) => {
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else if (this.validateForm) {
                this.person.user = this.userId
                http.post('person_detail', this.person)
                    .then((response) => {
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Person Created',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$vs.notify({
                            text: error.response.data,
                            color: 'danger',
                        })
                    })
            }
        },
    },
}
</script>

<style lang="css"></style>
