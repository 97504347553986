<template>
    <vx-card
        title="Advice"
        :subtitle="subTitle"
        :edit-action="true"
        @edit="show.adviceModal = true"
        title-color="#fff"
        subtitle-color="#fff"
        content-color="#fff"
        card-background="rgba(var(--vs-secondary),0.65)"
    >
        <ul v-if="advice.appointment">
            <li class="flex items-center mb-4">
                <div class="mr-6">
                    <feather-icon icon="CalendarIcon" />
                </div>
                <div class="leading-tight">
                    <p
                        v-if="upcomingAppointment(advice.appointment.date_time)"
                        class="font-bold text-secondary"
                    >
                        Upcoming Appointment
                    </p>
                    <p v-else class="font-medium text-secondary">
                        Appointment Past
                    </p>
                    <span class="font-xs">{{
                        formatDateAndTime(advice.appointment.date_time)
                    }}</span>
                </div>
            </li>
        </ul>

        <vs-divider v-if="advice.appointment"></vs-divider>

        <ul>
            <li
                v-if="
                    advice.power_of_attorney !== null ||
                    advice.power_of_attorney_recommended
                "
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <div class="font-medium">Power of Attorney</div>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.power_of_attorney,
                                advice.power_of_attorney_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.power_of_attorney,
                                advice.power_of_attorney_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="
                    advice.protect_home !== null ||
                    advice.protect_home_recommended
                "
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">Home Protection</p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.protect_home,
                                advice.protect_home_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.protect_home,
                                advice.protect_home_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="
                    advice.life_insurance !== null ||
                    advice.life_insurance_recommended
                "
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">Life Insurance</p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.life_insurance,
                                advice.life_insurance_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.life_insurance,
                                advice.life_insurance_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="
                    advice.life_insurance_review !== null ||
                    advice.life_insurance_review_recommended
                "
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">Life Insurance Review</p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.life_insurance_review,
                                advice.life_insurance_review_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.life_insurance_review,
                                advice.life_insurance_review_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>

            <li
                v-if="
                    advice.inheritance_tax !== null ||
                    advice.inheritance_tax_recommended
                "
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">Inheritance Tax</p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.inheritance_tax,
                                advice.inheritance_tax_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.inheritance_tax,
                                advice.inheritance_tax_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="
                    advice.funeral_plan !== null ||
                    advice.funeral_plan_recommended
                "
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">Funeral Plan</p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.funeral_plan,
                                advice.funeral_plan_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.funeral_plan,
                                advice.funeral_plan_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="advice.business !== null || advice.business_recommended"
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">Business</p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.business,
                                advice.business_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.business,
                                advice.business_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="
                    advice.assets_abroad !== null ||
                    advice.assets_abroad_recommended
                "
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">Assets Abroad</p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.assets_abroad,
                                advice.assets_abroad_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.assets_abroad,
                                advice.assets_abroad_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="advice.disabled !== null || advice.disabled_recommended"
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">Disabled Beneficiaries</p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.disabled,
                                advice.disabled_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.disabled,
                                advice.disabled_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="advice.children !== null || advice.children_recommended"
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">Children</p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.children,
                                advice.children_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.children,
                                advice.children_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="advice.scotland1 !== null || advice.scotland1_recommended"
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">Scotland - Married with Children</p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.scotland1,
                                advice.scotland1_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.scotland1,
                                advice.scotland1_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="advice.scotland2 !== null || advice.scotland2_recommended"
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">
                        Scotland - Married without Children
                    </p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.scotland2,
                                advice.scotland2_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.scotland2,
                                advice.scotland2_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="advice.scotland3 !== null || advice.scotland3_recommended"
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">
                        Scotland - Not Married with Children
                    </p>
                    <vs-chip
                        class="font-xs"
                        :color="
                            label(
                                advice.scotland3,
                                advice.scotland3_recommended
                            )[0]
                        "
                        :class="'text-white'"
                        >{{
                            label(
                                advice.scotland3,
                                advice.scotland3_recommended
                            )[1]
                        }}
                    </vs-chip>
                </div>
            </li>
            <li
                v-if="
                    advice.right_to_occupy1 !== null ||
                    advice.right_to_occupy1_recommended
                "
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">
                        Right to Occupy - Leaving all residue to partner & has
                        children
                    </p>
                    <vs-chip
                        class="font-xs text-white"
                        :color="
                            label(
                                advice.right_to_occupy1,
                                advice.right_to_occupy1_recommended
                            )[0]
                        "
                        >{{
                            label(
                                advice.right_to_occupy1,
                                advice.right_to_occupy1_recommended
                            )[1]
                        }}</vs-chip
                    >
                </div>
            </li>
            <li
                v-if="
                    advice.right_to_occupy2 !== null ||
                    advice.right_to_occupy2_recommended
                "
                class="flex items-center mb-4"
            >
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-crosshair"
                        :style="{
                            background: `rgba(var(--vs-secondary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">
                        Right to Occupy - Not leaving all residue to partner
                    </p>
                    <vs-chip
                        class="font-xs text-white"
                        :color="
                            label(
                                advice.right_to_occupy2,
                                advice.right_to_occupy2_recommended
                            )[0]
                        "
                        >{{
                            label(
                                advice.right_to_occupy2,
                                advice.right_to_occupy2_recommended
                            )[1]
                        }}
                    </vs-chip>
                </div>
            </li>
        </ul>
        <p v-if="advice.notes">Notes : {{ advice.notes }}</p>
        <EditAdviceModal
            :show="show.adviceModal"
            :data="advice"
            @close="show.adviceModal = false"
            @newData="$emit('newData')"
        ></EditAdviceModal>
    </vx-card>
</template>

<script>
import EditAdviceModal from '@/views/pages/Advice/Modals/EditModal'
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'AdviceCard',
    props: ['advice', 'appointments'],
    mixins: [textFormat],
    components: {
        EditAdviceModal,
    },
    data() {
        return {
            show: {
                adviceModal: false,
            },
        }
    },
    computed: {
        collapsed() {
            if (this.advice.finalised) return true
            return false
        },
        subTitle() {
            var count = 0
            var subTitle = ''
            if (this.advice.protect_home) count++
            if (this.advice.power_of_attorney) count++
            if (this.advice.life_insurance) count++
            if (this.advice.life_insurance_review) count++
            if (this.advice.inheritance_tax) count++
            if (this.advice.funeral_plan) count++
            if (this.advice.assets_abroad) count++
            if (this.advice.business) count++
            if (this.advice.disabled) count++
            if (this.advice.children) count++
            if (this.advice.scotland1) count++
            if (this.advice.scotland2) count++
            if (this.advice.scotland3) count++
            if (this.advice.right_to_occupy1) count++
            if (this.advice.right_to_occupy2) count++
            if (count > 0)
                subTitle = `${count} Advice ${
                    count > 1 ? 'requests' : 'request'
                }`
            else return 'No advice requested'

            var asked = false
            if (this.advice.protect_home !== null) asked = true
            if (this.advice.power_of_attorney !== null) asked = true
            if (this.advice.life_insurance !== null) asked = true
            if (this.advice.life_insurance_review !== null) asked = true
            if (this.advice.inheritance_tax !== null) asked = true
            if (this.advice.funeral_plan !== null) asked = true
            if (this.advice.assets_abroad !== null) asked = true
            if (this.advice.business !== null) asked = true
            if (this.advice.disabled !== null) asked = true
            if (this.advice.children !== null) asked = true
            if (this.advice.scotland1 !== null) asked = true
            if (this.advice.scotland2 !== null) asked = true
            if (this.advice.scotland3 !== null) asked = true
            if (this.advice.right_to_occupy1 !== null) asked = true
            if (this.advice.right_to_occupy2 !== null) asked = true

            if (!asked) return 'Advice questions not completed'

            if (this.advice.to_follow_up) return 'To Follow Up'
            if (this.advice.call_not_answered) return 'Call Not Answered'
            if (this.advice.end_lost) return 'End - Lost No Taken'
            if (this.advice.end_no_contact) return 'End - Lost No Contact'
            if (this.advice.end_sold) return 'End - Sold'
            if (this.advice.finalised) return 'Completed'
            return 'To Contact - ' + subTitle
        },
    },
    methods: {
        label(value, recommended) {
            var colour = ''
            var label = ''

            if (value) colour = 'secondary'
            else if (value === null) {
                if (recommended) colour = 'secondary'
            } else if (!value) colour = 'secondary'

            if (value) label = 'Requested'
            else if (value === null) {
                if (recommended) label = 'Recommended to user'
                else label = 'Error'
            } else if (!value) label = 'Declined'

            return [colour, label]
        },
        upcomingAppointment(dateTime) {
            var now = new Date()
            var appointment = new Date(dateTime)
            return now < appointment
        },
    },
}
</script>
