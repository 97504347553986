<template>
    <vx-card
        :title="title"
        collapse-action
        title-color="#fff"
        subtitle-color="#fff"
        content-color="#fff"
        card-background="rgba(var(--vs-success),0.65)"
    >
        <div v-if="wills.length">
            <ul
                v-for="(willSet, index) in sortedWills"
                :key="index + 'willSet'"
                class="vx-timeline"
                :class="{ 'timeline-no-border': wills.length === 1 }"
            >
                <li v-for="(will, index) in willSet" :key="index + 'will'">
                    <div class="flex">
                        <div>
                            <div
                                class="timeline-icon"
                                :class="statusLabel(will)[1]"
                            >
                                <feather-icon
                                    icon="FileTextIcon"
                                    svgClasses="text-white stroke-current w-5 h-5"
                                />
                            </div>
                            <div class="timeline-info">
                                <p class="font-semibold text-white">
                                    {{ statusLabel(will)[0] }}
                                </p>
                                <span class="activity-desc">
                                    {{
                                        will.update ? 'Update' : 'Original'
                                    }}</span
                                >
                            </div>
                            <small
                                v-if="will.paid_date"
                                class="text-white activity-e-time"
                                >{{
                                    'Paid ' + formatDateAndTime(will.paid_date)
                                }}</small
                            >
                            <small
                                v-else-if="will.uploaded_at"
                                class="text-white activity-e-time"
                                >{{
                                    'Created ' +
                                    formatDateAndTime(will.uploaded_at)
                                }}</small
                            >
                            <small
                                v-if="will.coupon_used"
                                class="text-white activity-e-time"
                            >
                                - Coupon {{ will.coupon_used.code }}
                            </small>
                        </div>
                        <div class="ml-auto">
                            <vs-chip
                                v-if="selectedId === will.id"
                                class="bg-success"
                                color="white"
                                >Current</vs-chip
                            >
                        </div>
                        <div
                            v-if="selectedId && selectedId !== will.id"
                            class="ml-auto"
                        >
                            <vs-button
                                radius
                                type="flat"
                                icon-pack="feather"
                                icon="icon-eye"
                                @click="$emit('compare', will)"
                            />
                        </div>
                        <div>
                            <vs-button
                                v-if="selectedId !== will.id"
                                radius
                                type="flat"
                                icon-pack="feather"
                                icon="icon-chevron-right"
                                :to="{ name: 'will', params: { id: will.id } }"
                            />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div v-else-if="progress">
            <vs-progress
                height="7"
                :percent="progress"
                color="success"
            ></vs-progress>
        </div>
        <p v-else>Questionnaire not started</p>
        <div class="flex-row-reverse flex">
            <vs-button
                v-if="partnersWill"
                :to="{ name: 'will', params: { id: partnersWill } }"
                color="success"
                >Partners Mirror Will</vs-button
            >
        </div>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'WillsCard',
    mixins: [textFormat],
    props: ['wills', 'progress', 'selectedId', 'partnersWill'],
    components: {},
    data() {
        return {
            show: {},
        }
    },
    computed: {
        title() {
            if (this.wills.length) return 'Wills'
            else return 'Questionnaire Progress'
        },
        subTitle() {
            return 'Wills Card'
        },
        sortedWills() {
            var sortedWills = []
            var primaryWills = this.wills.filter((will) => !will.update_to)
            for (var i = 0; i < primaryWills.length; i++) {
                var willSet = this.wills.filter(
                    (will) => will.update_to === primaryWills[i].id
                )
                willSet.push(primaryWills[i])
                willSet = willSet.sort(
                    (a, b) => new Date(a.uploaded_at) - new Date(b.uploaded_at)
                )
                sortedWills.push(willSet)
            }
            return sortedWills
        },
    },
    methods: {
        statusLabel(data) {
            if (data.in_storage) return ['End - In Storage', 'bg-success']
            if (data.end_lost) return ['End - Lost', 'bg-success']
            if (data.end_no_response) return ['End - No Response', 'bg-success']
            if (
                (data.printing_required && data.printed && data.checked) ||
                (!data.printing_required && data.checked)
            )
                return ['End Complete', 'bg-success']
            if (data.checked && data.printing_required && !data.printed)
                return ['Approved - To Print', 'bg-success']
            if (data.checked_pre_final)
                return ['Checked - To Approve', 'bg-success']
            if (data.checked_contact_client_advice)
                return ['Contact - Estate Planner', 'bg-success']
            if (data.checked_contact_client_issue)
                return ['Contact - Admin', 'bg-success']
            if (data.paid) return ['Paid - To Check', 'bg-success']
            return ['Created - To Pay', 'bg-success']
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/components/vxTimeline.scss';
.timeline-no-border {
    border-left: none !important;
}

#avatar-col {
    width: 10rem;
}

#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: 0.8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
    only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }
}
</style>
