<template>
    <!-- compose email -->
    <vs-prompt
        title="Send Partner Mirror Will Coupon"
        accept-text="Create"
        @cancel="close"
        @accept="save"
        @close="close"
        :is-valid="validateForm"
        :active.sync="showModal"
    >
        <VuePerfectScrollbar
            class="scroll-area p-4"
            :settings="settings"
            :key="$vs.rtl"
        >
            <vs-alert class="mb-6"
                >This will create a free coupon for the partner</vs-alert
            >

            <vs-alert
                v-if="
                    !user.details.partner_full_name ||
                    !user.details.partner_gender
                "
                class="mb-6 bg-danger text-white"
                >No partner name or gender set, please set these before
                proceeding</vs-alert
            >
            <vs-alert v-else class="mb-6"
                >Partner name : {{ user.details.partner_full_name }}</vs-alert
            >

            <form
                @submit.prevent
                id="loading-container"
                class="vs-con-loading__container"
            >
                <div class="vx-row">
                    <div
                        class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full"
                    >
                        <div class="flex mb-6">
                            <label for="" class="mr-auto">Send to User</label>
                            <vs-switch
                                v-model="formData.send_to_user"
                                color="success"
                                vs-icon="done"
                            >
                            </vs-switch>
                        </div>
                    </div>
                </div>

                <vs-input
                    v-show="formData.send_to_user"
                    name="mailTo"
                    label-placeholder="User Email"
                    v-model="formData.user_email"
                    class="w-full mb-6"
                    :danger="!validateUserEmail"
                    :success="validateUserEmail"
                    val-icon-success="done"
                    :color="validateUserEmail ? 'success' : 'danger'"
                />

                <vs-input
                    v-show="formData.send_to_user"
                    name="mailTo"
                    label-placeholder="User Phone"
                    v-model="formData.user_phone"
                    class="w-full mb-6"
                    :danger="!validateUserPhone"
                    :success="validateUserPhone"
                    val-icon-success="done"
                    :color="validateUserPhone ? 'success' : 'danger'"
                />

                <div class="vx-row">
                    <div
                        class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full"
                    >
                        <div class="flex mb-6">
                            <label for="" class="mr-auto"
                                >Send to Partner</label
                            >
                            <vs-switch
                                v-model="formData.send_to_partner"
                                color="success"
                                vs-icon="done"
                            >
                            </vs-switch>
                        </div>
                    </div>
                </div>

                <vs-input
                    v-show="formData.send_to_partner"
                    name="mailTo"
                    label-placeholder="Partner Email"
                    v-model="formData.partner_email"
                    class="w-full mb-6"
                    :danger="!validatePartnerEmail"
                    :success="validatePartnerEmail"
                    val-icon-success="done"
                    :color="validatePartnerEmail ? 'success' : 'danger'"
                />

                <vs-input
                    v-show="formData.send_to_partner"
                    name="mailTo"
                    label-placeholder="Partner Phone"
                    v-model="formData.partner_phone"
                    class="w-full mb-6"
                    :danger="!validatePartnerPhone"
                    :success="validatePartnerPhone"
                    val-icon-success="done"
                    :color="validatePartnerPhone ? 'success' : 'danger'"
                />
            </form>
        </VuePerfectScrollbar>
    </vs-prompt>
</template>

<script>
import { http } from '@/services'
import { functions } from '@/mixins/functions'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    name: 'CreatePartnerCouponModal',
    props: ['show', 'user'],
    mixins: [functions],
    components: {
        VuePerfectScrollbar,
    },
    watch: {
        user: {
            deep: true,
            immediate: true,
            handler(value) {
                if (value) {
                    this.formData.user_id = value.id
                    this.formData.partner_phone = value.details.partner_phone
                    this.formData.partner_email = value.details.partner_email
                    this.formData.user_phone = value.details.phone
                    this.formData.user_email = value.email
                }
            },
        },
    },
    computed: {
        showModal: {
            get() {
                return this.show
            },
            set(value) {
                // this.$emit('show', value)
            },
        },
        validatePartnerEmail() {
            return this.validEmail(this.formData.partner_email)
        },
        validatePartnerPhone() {
            if (this.formData.partner_phone) return true
            return false
        },
        validateUserEmail() {
            return this.validEmail(this.formData.user_email)
        },
        validateUserPhone() {
            if (this.formData.user_phone) return true
            return false
        },
        validateForm() {
            if (this.formData.user_id) {
                if (
                    this.formData.send_to_partner &&
                    !this.validatePartnerEmail &&
                    !this.validatePartnerPhone
                )
                    return false
                if (
                    this.formData.send_to_user &&
                    !this.validateUserEmail &&
                    !this.validateUserPhone
                )
                    return false

                return true
            }
            return false
        },
    },
    data() {
        return {
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            formData: {
                user_id: null,
                partner_email: null,
                partner_phone: null,
                send_to_user: false,
                send_to_partner: false,
                user_phone: null,
                user_email: null,
            },
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        clearForm() {
            this.formData.partner_email = ''
        },
        save() {
            if (
                !this.user.details.partner_full_name ||
                !this.user.details.partner_gender
            )
                return

            this.$vs.loading({
                background: this.backgroundLoading,
                color: this.colorLoading,
                container: '#loading-container',
                scale: 0.45,
            })

            http.post('user_create_partner_coupon', this.formData)
                .then((response) => {
                    this.$emit('close')
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                    this.$emit('refresh')
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Partner coupon created',
                        icon: 'feather',
                        iconPack: 'icon-check',
                        color: 'primary',
                    })
                    this.$emit('refreshCoupons')
                })
                .catch((error) => {
                    console.log(error.response.data)
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                })
        },
    },
}
</script>
