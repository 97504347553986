<template>
    <vs-prompt
        :title="title"
        accept-text="Send"
        @cancel="clearAndClose"
        @accept="save"
        @close="close"
        :is-valid="validateForm"
        :active.sync="showModal"
    >
        <vs-alert class="mb-6"
            >The user will receive a email with a link to setup their
            subscription</vs-alert
        >
        <form
            @submit.prevent
            id="loading-container"
            class="vs-con-loading__container"
        >
            <vs-input
                name="first_payment_date"
                type="date"
                label-placeholder="Fist Payment Date"
                v-model="form.first_payment_date"
                class="w-full mb-6"
            />
        </form>
    </vs-prompt>
</template>

<script>
import { http } from '@/services'

export default {
    name: 'GocardlessSubscriptionModal',
    components: {},
    props: ['userId', 'show'],
    watch: {
        userId: {
            deep: true,
            immediate: true,
            handler(data) {
                this.form.user_pk = data
            },
        },
    },
    data() {
        return {
            form: {
                user_pk: null,
                first_payment_date: null,
            },
        }
    },
    computed: {
        validateForm() {
            return !!this.form.first_payment_date
        },
        title() {
            return `Gocardless Subscription Setup`
        },
        showModal: {
            get() {
                return this.show
            },
            set(value) {
                // this.$emit('show', value)
            },
        },
    },
    methods: {
        save() {
            http.post('send_subscription_invitation', this.form)
                .then((response) => {
                    this.$vs.notify({
                        text: 'Invitation sent',
                        color: 'success',
                    })
                    this.clearAndClose()
                })
                .catch((error) => {
                    console.log(error)
                    this.clearAndClose()
                    this.$vs.notify({
                        text: 'Invitation failed',
                        color: 'secondary',
                    })
                })
        },
        close() {
            this.$emit('close')
        },
        clearAndClose() {
            this.form.first_payment_date = null
            this.form.user_pk = null
            this.close()
        },
    },
}
</script>
