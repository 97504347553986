<template>
    <div v-if="data">
        <div class="flex flex-row-reverse w-full">
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> Action </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu
                    class="w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6"
                >
                    <vs-dropdown-item v-on:click="$emit('composeEmail')">
                        Compose Email
                    </vs-dropdown-item>
                    <vs-dropdown-item v-on:click="$emit('composeSms')">
                        Compose Sms
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-on:click="confirmPasswordResetEmail"
                        divider
                    >
                        Send Password Reset Email
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-on:click="show.usernamePasswordModal = true"
                    >
                        Username and Password
                    </vs-dropdown-item>
                    <vs-dropdown-item v-on:click="loginAsUser">
                        Login as user
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-on:click="show.createPartnerCouponModal = true"
                        divider
                    >
                        Send Partner Mirror Will Coupon
                    </vs-dropdown-item>

                    <vs-dropdown-item divider v-on:click="confirmDeleteUser">
                        Delete User
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>
        <UsernamePasswordChangeModal
            :show="show.usernamePasswordModal"
            :user="data"
            @close="show.usernamePasswordModal = false"
        />
        <CreatePartnerCouponModal
            :show="show.createPartnerCouponModal"
            :user="data"
            @close="show.createPartnerCouponModal = false"
            @refreshCoupons="$emit('refreshCoupons')"
        />
    </div>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { http } from '@/services'

import UsernamePasswordChangeModal from '../Models/UsernamePasswordChangeModal'
import CreatePartnerCouponModal from '../Models/CreatePartnerCouponModal'

export default {
    name: 'AccountCard',
    props: ['data'],
    mixins: [textFormat],
    components: {
        UsernamePasswordChangeModal,
        CreatePartnerCouponModal,
    },
    data() {
        return {
            show: {
                deleteModal: false,
                confirmModal: false,
                usernamePasswordModal: false,
                createPartnerCouponModal: false,
            },
            confirm: {
                title: null,
                action: null,
            },
        }
    },
    computed: {
        hasPartnerCoupon() {
            if (
                this.data.coupons &&
                this.data.coupons.find((coupon) => coupon.partner_code)
            )
                return true
            return false
        },
    },
    methods: {
        deleteUser() {
            http.delete('user_detail', { params: { id: this.data.id } })
                .then((response) => {
                    this.show.deleteModal = false
                })
                .then((response) => {
                    this.$vs.notify({
                        title: 'User Deleted',
                        text: this.data.details.full_name || '',
                        color: 'success',
                    })
                    this.$router.push('/users/all')
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        loginAsUser() {
            http.post('login_as_user', { user_id: this.data.id })
                .then((response) => {
                    if (response.data.access && response.data.refresh) {
                        var base =
                            process.env.NODE_ENV === 'production'
                                ? 'https://wills.mylastwill.co.uk/login/'
                                : 'http://127.0.0.1:7000/login/'
                        var url =
                            base +
                            response.data.access +
                            '/' +
                            response.data.refresh
                        var win = window.open(url, '_blank')
                        win.focus()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        sendPasswordResetEmail() {
            http.post('user_send_password_reset', {
                user_id: this.data.id,
            }).then((response) => {
                this.$vs.notify({
                    title: 'Success',
                    text: `Password reset sent to ${this.data.email}`,
                    color: 'success',
                })
            })
        },
        confirmPasswordResetEmail() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: `Send Password Reset Email`,
                text: ` ${this.data.email}`,
                accept: this.sendPasswordResetEmail,
            })
        },
        confirmDeleteUser() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete User`,
                text: ` ${this.data.email}`,
                accept: this.deleteUser,
            })
        },
    },
}
</script>
