<template>
    <div id="page-user-view">
        <div class="vx-row">
            <div v-if="userData.data" class="vx-col w-full mb-3">
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <AccountCard
                            :data="userData.data"
                            @composeEmail="show.emailComposeModal = true"
                            @composeSms="show.smsComposeModal = true"
                            @refreshCoupons="couponsFetch"
                        ></AccountCard>
                    </div>
                </div>

                <div class="vx-row"></div>
                <div class="vx-row flex flex-row">
                    <div
                        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 mb-base"
                    >
                        <div class="vx-row">
                            <div
                                v-if="userData.data"
                                class="vx-col w-full mb-base"
                            >
                                <PersonalDetailsCard
                                    :locked="false"
                                    :user="{
                                        email: userData.data.email,
                                        details: userData.data.details,
                                    }"
                                    :userAccount="userData.data"
                                    hideUserLink
                                    @newData="userData.data.details = $event"
                                ></PersonalDetailsCard>
                            </div>
                            <div
                                v-if="userData.data"
                                class="vx-col w-full mb-base"
                            >
                                <WillsCard
                                    :progress="
                                        userData.data.progress
                                            .percentage_complete
                                    "
                                    :wills="userData.data.wills"
                                ></WillsCard>
                            </div>

                            <div class="vx-col w-full mb-base">
                                <PeopleCard
                                    :fetchPeople="true"
                                    :charities="peopleGroupsCharities.charities"
                                    :groups="peopleGroupsCharities.groups"
                                    :people="peopleGroupsCharities.people"
                                    :userId="userData.data.id"
                                    @peopleListFetch="peopleListFetch"
                                ></PeopleCard>
                            </div>
                        </div>
                    </div>
                    <div
                        class="vx-col order-first sm:order-first md:order-none lg:order-none xl:order-none w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 mb-base"
                    >
                        <div class="vx-row">
                            <div class="vx-col w-full mb-base">
                                <TimeLineCard
                                    :logsBroker="userData.data.logs_broker"
                                    :logsSystem="userData.data.logs_system"
                                    :user="userData.data"
                                ></TimeLineCard>
                            </div>
                        </div>
                    </div>
                    <div
                        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 mb-base"
                    >
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <EmailCard
                                    :emails="userData.data.emails"
                                    :user="userData.data"
                                ></EmailCard>
                            </div>
                            <div
                                v-if="userData.data"
                                class="vx-col w-full mb-base"
                            >
                                <AdviceCard
                                    :advice="userData.data.advice"
                                    :collapsed="false"
                                    @newData="adviceFetch"
                                ></AdviceCard>
                            </div>
                            <div class="vx-col w-full mb-base">
                                <NotesCard
                                    :notes="userData.data.user_notes"
                                    :pk="userData.data.id"
                                    apiUrl="user_note"
                                    title="User Notes"
                                    type="user"
                                    @notesFetch="notesFetch"
                                ></NotesCard>
                            </div>
                            <div class="vx-col w-full mb-base">
                                <CouponsCard
                                    :couponsData="userData.data.coupons"
                                    :userId="userData.data.id"
                                    @newData="couponsFetch"
                                ></CouponsCard>
                            </div>

                            <div
                                v-if="userData.data"
                                class="vx-col w-full mb-base"
                            >
                                <GocardlessCard
                                    :gocardless="userData.gocardless"
                                    :userId="userData.data.id"
                                ></GocardlessCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <EmailComposeModal
            v-if="userData.data"
            :show="show.emailComposeModal"
            :user="{ id: userData.data.id, email: userData.data.email }"
            @show="show.emailComposeModal = $event"
            @showDraft="handleDraftEmail"
        ></EmailComposeModal>
        <SmsComposeModal
            v-if="userData.data"
            :phone="phoneNumber"
            :show="show.smsComposeModal"
            :user="{ id: userData.data.id, email: userData.data.email }"
            @show="show.smsComposeModal = $event"
            @showDraft="handleDraftSms"
        ></SmsComposeModal>

        <vs-avatar
            v-show="show.emailDraftButton && !show.emailComposeModal"
            class="email-head"
            color="secondary"
            icon="email"
            size="70px"
            @click="showDraftEmail"
        />
        <vs-avatar
            v-show="show.smsDraftButton && !show.smsComposeModal"
            class="sms-head"
            color="secondary"
            icon="chat"
            size="70px"
            @click="showDraftSms"
        />
    </div>
</template>
]

<script>
import { http } from '@/services'

import WillsCard from './Cards/Wills'
import AdviceCard from '../../Wills/Will/Cards/Advice'
import AccountCard from './Cards/Account'
import GocardlessCard from './Cards/Gocardless'
import NotesCard from '@/components/common/Notes'
import TimeLineCard from './Cards/TimeLine'
import CouponsCard from './Cards/Coupons'
import EmailCard from './Cards/Email'
import PersonalDetailsCard from '@/views/pages/Wills/Will/Cards/PersonalDetails'
import EmailComposeModal from '@/views/pages/Email/EmailComposeModal'
import SmsComposeModal from '@/views/pages/Email/SmsComposeModal'
import PeopleCard from '@/views/pages/Wills/Will/Cards/People'

export default {
    name: 'UserView',
    components: {
        PeopleCard,
        WillsCard,
        AccountCard,
        AdviceCard,
        GocardlessCard,
        NotesCard,
        TimeLineCard,
        CouponsCard,
        EmailCard,
        PersonalDetailsCard,
        EmailComposeModal,
        SmsComposeModal,
    },
    created() {
        this.userDataFetch(this.$route.params.id)
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.userDataFetch(this.$route.params.id)
    },
    watch: {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    data() {
        return {
            userData: {
                data: null,
                gocardless: null,
            },
            backPath: null,
            show: {
                smsComposeModal: false,
                emailComposeModal: false,
                emailDraftButton: false,
                smsDraftButton: false,
            },
            peopleGroupsCharities: {
                people: [],
                groups: [],
                charities: [],
            },
        }
    },
    computed: {
        phoneNumber() {
            if (this.userData.data.details)
                return this.userData.data.details.phone
            return null
        },
    },
    methods: {
        userDataFetch(user_id) {
            this.$vs.loading()
            http.get('user_detail', { params: { id: user_id } })
                .then((response) => {
                    this.userData.data = response.data
                    if (
                        this.userData.data.gocardless &&
                        this.userData.data.gocardless.go_card_less_customer_id
                    )
                        this.gocardlessCustomerFetch()
                    else this.userData.gocardless = null
                    this.$vs.loading.close()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close()
                })
        },
        adviceFetch() {
            http.get('advice_detail', {
                params: { id: this.userData.data.advice.id },
            })
                .then((response) => {
                    this.userData.data.advice = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        couponsFetch() {
            http.get('user_coupons', {
                params: { user_id: this.userData.data.id },
            })
                .then((response) => {
                    this.userData.data.coupons = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        gocardlessCustomerFetch() {
            http.get('gocardless_customer_detail', {
                params: {
                    customer_id: this.userData.data.gocardless
                        .go_card_less_customer_id,
                },
            })
                .then((response) => {
                    this.userData.gocardless = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        notesFetch() {
            http.get('user_note', {
                params: { user_id: this.userData.data.id },
            })
                .then((response) => {
                    this.userData.data.user_notes = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        handleDraftEmail(value) {
            this.show.emailDraftButton = value
        },
        showDraftEmail() {
            this.show.emailDraftButton = false
            this.show.emailComposeModal = true
        },
        handleDraftSms(value) {
            this.show.smsDraftButton = value
        },
        showDraftSms() {
            this.show.smsDraftButton = false
            this.show.smsComposeModal = true
        },
        peopleListFetch() {
            http.get('people_in_will_list', {
                params: { user_id: this.userData.data.id },
            })
                .then((response) => {
                    this.peopleGroupsCharities.people = response.data.people
                    this.peopleGroupsCharities.groups = response.data.groups
                    this.peopleGroupsCharities.charities =
                        response.data.charities
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

<style lang="scss">
#avatar-col {
    width: 10rem;
}

#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: 0.8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
    only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }
}
</style>
