<template>
    <!-- compose sms -->
    <vs-prompt
        class="email-compose"
        title="New Message"
        accept-text="Send"
        @cancel="clearFields"
        @accept="sendSms"
        @close="close"
        :is-valid="validateForm"
        :active.sync="showModal"
    >
        <VuePerfectScrollbar
            class="scroll-area p-4"
            :settings="settings"
            :key="$vs.rtl"
        >
            <form
                @submit.prevent
                id="loading-container"
                class="vs-con-loading__container"
            >
                <vs-input
                    label-placeholder="Phone"
                    v-model="data.phone"
                    class="w-full mb-6"
                    :danger="!validateForm && data.phone != ''"
                    val-icon-danger="clear"
                    :success="validateForm"
                    val-icon-success="done"
                    :color="validateForm ? 'success' : 'danger'"
                />
                <vs-textarea
                    name="mailSubject"
                    label-placeholder="Subject"
                    v-model="data.body"
                    class="w-full mb-6"
                />
                {{ data.body.length }}/160
            </form>
            <div class="flex flex-row-reverse">
                <vs-button
                    @click="close"
                    class="ml-3"
                    color="primary"
                    type="flat"
                    iconPack="feather"
                    icon="icon-chevron-down"
                    >Minimise</vs-button
                >
            </div>
        </VuePerfectScrollbar>
    </vs-prompt>
</template>

<script>
import { http } from '@/services'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    name: 'SmsComposeModal',
    props: ['show', 'user', 'phone'],
    components: {
        VuePerfectScrollbar,
    },
    watch: {
        user: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.data.user = value.id
                    this.data.to = value.email
                }
            },
        },
        phone: {
            immediate: true,
            handler(value) {
                this.data.phone = value
            },
        },
    },
    computed: {
        showModal: {
            get() {
                return this.show
            },
            set(value) {
                // this.$emit('show', value)
            },
        },
        validateForm() {
            if (this.data.phone) return true
            return false
        },
    },
    data() {
        return {
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            data: {
                phone: '',
                body: '',
                user: null,
            },
            errors: [],
        }
    },
    methods: {
        clearFields() {
            this.data.body = ''
            this.close()
        },
        close() {
            if (this.data.body) this.$emit('showDraft', true)
            else this.$emit('showDraft', false)
            this.$emit('show', false)
        },
        sendSms() {
            this.$vs.loading({
                background: this.backgroundLoading,
                color: this.colorLoading,
                container: '#loading-container',
                scale: 0.45,
            })

            http.post('send_sms_to_user', this.data)
                .then((response) => {
                    this.$emit('show', false)
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                    this.clearFields()
                    this.$emit('refresh')
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Sms Sent',
                        icon: 'feather',
                        iconPack: 'icon-check',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                    this.$vs.notify({
                        title: 'Success',
                        text: error.response.data,
                        icon: 'feather',
                        iconPack: 'icon-alert-circle',
                        color: 'danger',
                    })
                })
        },
    },
}
</script>

<style lang="scss">
.con-vs-dialog.email-compose {
    .vs-dialog {
        max-width: 530px;
        // max-height: 95vh;

        .vs-dialog-text {
            padding: 0;

            .scroll-area {
                max-height: 75vh;
            }

            .compose-mail-attachment {
                .con-input-upload {
                    margin: 0;
                }
            }
        }
    }

    .con-img-upload {
        .con-input-upload {
            height: 2.5rem;
            width: 100%;
        }

        .img-upload {
            // height: 4rem;
            margin: 0;
            margin-bottom: 0.5rem;
            margin-right: 1.5rem;
        }
    }

    .quill-editor {
        margin-bottom: 1.5rem;
    }

    .ql-editor {
        height: 150px;
    }
}
</style>
