<template>
    <vs-prompt
        :title="title"
        accept-text="Send"
        @cancel="clearAndClose"
        @accept="save"
        @close="close"
        :is-valid="validateForm"
        :active.sync="showModal"
    >
        <form
            @submit.prevent
            id="loading-container"
            class="vs-con-loading__container"
        >
            <vs-input
                name="amount"
                type="number"
                label-placeholder="Amount"
                v-model="form.amount"
                class="w-full mb-6"
            />
            <vs-input
                name="description"
                label-placeholder="Description"
                v-model="form.description"
                class="w-full mb-6"
            />
            <vs-alert active="true" class="mb-6">
                Mark the users latest will as paid and requiring printing?
            </vs-alert>
            <vs-checkbox v-model="form.will" class="mb-3"
                >Will Paid</vs-checkbox
            >
            <vs-checkbox v-model="form.printing">Printing required</vs-checkbox>
        </form>
    </vs-prompt>
</template>

<script>
import { http } from '@/services'

export default {
    name: 'GocardlessCustomAmount',
    components: {},
    props: ['userId', 'show'],
    watch: {
        userId: {
            deep: true,
            immediate: true,
            handler(data) {
                this.form.user = data
            },
        },
    },
    data() {
        return {
            form: {
                user: null,
                amount: null,
                description: null,
                will: false,
                printing: false,
            },
        }
    },
    computed: {
        validateForm() {
            return !!(
                this.form.description &&
                this.form.user &&
                this.form.amount
            )
        },
        title() {
            return `Gocardless Custom Amount`
        },
        showModal: {
            get() {
                return this.show
            },
            set(value) {
                // this.$emit('show', value)
            },
        },
    },
    methods: {
        save() {
            http.post('send_custom_amount_invitation', this.form)
                .then((response) => {
                    this.$vs.notify({
                        text: 'Payment request sent',
                        color: 'success',
                    })
                    this.close()
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'danger',
                    })
                    console.log(error.response.data)
                    this.close()
                })
        },
        close() {
            this.$emit('close')
        },
        clearAndClose() {
            this.form.user = null
            this.form.amount = null
            this.form.description = null
            this.form.will = null
            this.form.printing = null
            this.close()
        },
    },
}
</script>
