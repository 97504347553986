<template>
    <!-- compose email -->
    <vs-prompt
        class="email-compose"
        title="New Message"
        accept-text="Send"
        @cancel="clearFields"
        @accept="sendMail"
        @close="close"
        :is-valid="validateForm"
        :active.sync="showModal"
    >
        <VuePerfectScrollbar
            class="scroll-area p-4"
            :settings="settings"
            :key="$vs.rtl"
        >
            <form
                @submit.prevent
                id="loading-container"
                class="vs-con-loading__container"
            >
                <vs-input
                    name="mailTo"
                    label-placeholder="To"
                    v-model="data.to"
                    class="w-full mb-6"
                    :danger="!validateForm && data.to !== ''"
                    val-icon-danger="clear"
                    :success="validateForm"
                    val-icon-success="done"
                    :color="validateForm ? 'success' : 'danger'"
                />
                <vs-input
                    name="mailSubject"
                    label-placeholder="Subject"
                    v-model="data.subject"
                    class="w-full mb-6"
                />
                <vs-input
                    name="mailCC"
                    label-placeholder="CC"
                    v-model="data.cc"
                    class="w-full mb-6"
                />
                <vs-input
                    name="mailBCC"
                    label-placeholder="BCC"
                    v-model="data.bcc"
                    class="w-full mb-6"
                />
                <quill-editor v-model="data.body" :options="editorOption" />
                <div class="flex justify-between">
                    <BtnFileSelect
                        @fileChanged="data.file = $event"
                        :file="data.file"
                    ></BtnFileSelect>
                    <vs-button
                        @click="close"
                        class="ml-3"
                        color="primary"
                        type="flat"
                        iconPack="feather"
                        icon="icon-chevron-down"
                        >Minimise</vs-button
                    >
                </div>
            </form>
        </VuePerfectScrollbar>
    </vs-prompt>
</template>

<script>
import { http } from '@/services'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import BtnFileSelect from '@/components/common/BtnFileSelect.vue'

export default {
    name: 'EmailComposeModal',
    props: ['show', 'user'],
    components: {
        quillEditor,
        VuePerfectScrollbar,
        BtnFileSelect,
    },
    watch: {
        user: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.data.user = value.id
                    this.data.to = value.email
                }
            },
        },
    },
    computed: {
        showModal: {
            get() {
                return this.show
            },
            set(value) {
                // this.$emit('show', value)
            },
        },
        validateForm() {
            if (this.data.to) return true
            return false
        },
    },
    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: [
                        [
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'link',
                            'blockquote',
                            'code-block',
                        ],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ font: [] }],
                    ],
                },
                placeholder: 'Message',
            },
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            data: {
                file: null,
                subject: '',
                to: '',
                cc: '',
                bcc: '',
                body: '',
                user: null,
            },
            errors: [],
        }
    },
    methods: {
        clearFields() {
            this.data.file = null
            this.data.subject = ''
            this.data.cc = ''
            this.data.bcc = ''
            this.data.body = ''
            this.close()
        },
        close() {
            if (this.data.body) this.$emit('showDraft', true)
            else this.$emit('showDraft', false)
            this.$emit('show', false)
        },
        sendMail() {
            this.$vs.loading({
                background: this.backgroundLoading,
                color: this.colorLoading,
                container: '#loading-container',
                scale: 0.45,
            })

            var formData = new FormData()
            formData.append('to', this.data.to)
            formData.append('body', this.data.body)
            if (this.data.file) formData.append('file', this.data.file)
            formData.append('cc', this.data.cc)
            formData.append('bcc', this.data.bcc)
            formData.append('subject', this.data.subject)
            if (this.data.user) formData.append('user', this.data.user)

            http.post('send_mail_to_user', formData)
                .then((response) => {
                    this.$emit('show', false)
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                    this.clearFields()
                    this.$emit('refresh')
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Email Sent',
                        icon: 'feather',
                        iconPack: 'icon-check',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    console.log(error.response.data)
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                    this.$vs.notify({
                        title: 'Success',
                        text: error.response.data,
                        icon: 'feather',
                        iconPack: 'icon-alert-circle',
                        color: 'danger',
                    })
                })
        },
    },
}
</script>

<style lang="scss">
.con-vs-dialog.email-compose {
    .vs-dialog {
        max-width: 530px;
        // max-height: 95vh;

        .vs-dialog-text {
            padding: 0;

            .scroll-area {
                max-height: 75vh;
            }

            .compose-mail-attachment {
                .con-input-upload {
                    margin: 0;
                }
            }
        }
    }

    .con-img-upload {
        .con-input-upload {
            height: 2.5rem;
            width: 100%;
        }

        .img-upload {
            // height: 4rem;
            margin: 0;
            margin-bottom: 0.5rem;
            margin-right: 1.5rem;
        }
    }

    .quill-editor {
        margin-bottom: 1.5rem;
    }

    .ql-editor {
        height: 150px;
    }
}
</style>
