<template>
    <SideBarModal title="Profile" :show="show" @close="$emit('close')">
        <template v-slot:content>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input
                        class="w-full"
                        label-placeholder="Full Name"
                        v-model="details.full_name"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-1/2">
                    <v-select
                        v-model="details.marital_status"
                        :options="martialStatusOptions"
                        placeholder="Marital Status"
                    >
                    </v-select>
                </div>
                <div class="vx-col w-1/2">
                    <v-select
                        v-model="details.sex"
                        :options="genderOptions"
                        placeholder="Gender"
                    >
                    </v-select>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-1/3">
                    <vs-input
                        class="w-full"
                        v-model="details.dob_day"
                        label-placeholder="Dob day"
                    />
                </div>
                <div class="vx-col w-1/3">
                    <vs-input
                        class="w-full"
                        v-model="details.dob_month"
                        label-placeholder="Dob month"
                    />
                </div>
                <div class="vx-col w-1/3">
                    <vs-input
                        class="w-full"
                        v-model="details.dob_year"
                        label-placeholder="Dob year"
                    />
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        class="w-full"
                        v-model="details.address_line_1"
                        label-placeholder="Address 1"
                    />
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        class="w-full"
                        v-model="details.address_line_2"
                        label-placeholder="Address 1"
                    />
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        class="w-full"
                        v-model="details.post_town"
                        label-placeholder="Post town"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input
                        class="w-full"
                        v-model="details.post_code"
                        label-placeholder="Postcode"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <v-select
                        v-model="details.country"
                        :options="countryOptions"
                        placeholder="Country"
                    >
                    </v-select>
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        class="w-full"
                        v-model="details.phone"
                        label-placeholder="Phone"
                    />
                </div>
            </div>

            <vs-divider />
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        class="w-full"
                        v-model="details.partner_full_name"
                        label-placeholder="Partner Full Name"
                    />
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <v-select
                        v-model="details.partner_gender"
                        :options="genderOptions"
                        placeholder="Parnter Gender"
                    >
                    </v-select>
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        class="w-full"
                        v-model="details.partner_phone"
                        label-placeholder="Partner Phone"
                    />
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        class="w-full"
                        v-model="details.partner_email"
                        label-placeholder="Partner Email"
                    />
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <vs-button class="mr-6" @click="detailsSave">Save </vs-button>
            <vs-button type="border" color="danger" @click="$emit('close')"
                >Cancel
            </vs-button>
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'
import vSelect from 'vue-select'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'PersonalDetailsModal',
    components: {
        SideBarModal,
        vSelect,
    },
    props: ['personalDetails', 'show'],
    watch: {
        personalDetails: {
            deep: true,
            immediate: true,
            handler(data) {
                this.details = { ...data }
            },
        },
    },
    data() {
        return {
            person: null,
            details: null,
            countryOptions: [
                'England',
                'Wales',
                'Scotland',
                'Northern Ireland',
            ],
            genderOptions: ['Male', 'Female'],
            martialStatusOptions: [
                'Single',
                'Married',
                'Engaged',
                'Co-habiting',
                'Civil Partner',
                'Separated',
            ],
        }
    },
    computed: {},
    methods: {
        detailsSave() {
            if (this.details && this.details.id) {
                delete this.details.partner
                http.patch('personal_details', this.details)
                    .then((response) => {
                        this.$emit('newData', response.data)
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
}
</script>
