<template>
    <SideBarModal :title="charityName" :show="show" @close="$emit('close')">
        <template v-if="charity" v-slot:content>
            <vs-collapse accordion open>
                <div class="vx-row mb-2">
                    <div class="vx-col w-full">
                        <vs-input
                            class="w-full"
                            :danger="!charity.charity_name"
                            label-placeholder="Charity Name"
                            v-model="charity.charity_name"
                        />
                    </div>
                </div>

                <div class="vx-row mb-2">
                    <div class="vx-col w-full">
                        <vs-input
                            class="w-full"
                            :danger="!charity.charity_number"
                            label-placeholder="Charity Number"
                            v-model="charity.charity_number"
                        />
                    </div>
                </div>

                <vs-collapse-item open>
                    <div slot="header">Distribution</div>

                    <div class="flex mb-3">
                        <div class="w-1/2 object-left">
                            <vs-checkbox v-model="charity.distribution_primary"
                                >Primary</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="flex flex-row mb-3">
                        <div class="w-2/3">
                            <div class="vx-col w-2/3">
                                <vs-input
                                    label-placeholder="Percentage"
                                    v-model="charity.distribution_percentage"
                                />
                            </div>
                        </div>
                    </div>

                    <vs-divider></vs-divider>

                    <div class="vx-row mb-6">
                        <div class="vx-col w-1/2">
                            <vs-checkbox v-model="charity.distribution_backup"
                                >Backup</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <div class="w-2/3">
                            <vs-input
                                class="w-full"
                                label-placeholder="Percentage"
                                v-model="charity.distribution_backup_percentage"
                            />
                        </div>
                    </div>
                </vs-collapse-item>
            </vs-collapse>
        </template>
        <template v-slot:buttons>
            <vs-button
                :disabled="!validateForm"
                class="mr-6"
                @click="charitySave"
                >Save
            </vs-button>
            <vs-button type="border" color="danger" @click="$emit('close')"
                >Cancel
            </vs-button>
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'
import { TextFormat } from '@/mixins/TextFormat'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'CharityModal',
    components: {
        SideBarModal,
    },
    props: ['charityId', 'show', 'userId'],
    watch: {
        charityId: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.charityFetch(value)
                } else {
                    this.clearForm()
                }
            },
        },
    },
    data() {
        return {
            charity: {
                charity_name: null,
                charity_number: null,
                distribution_primary: false,
                distribution_percentage: 0,
                distribution_backup: false,
                distribution_backup_percentage: 0,
            },
        }
    },
    computed: {
        validateForm() {
            if (this.charity.charity_name) return true
            return false
        },
        charityName() {
            if (this.charityId) return 'Edit Charity'
            return 'Create Chairty'
        },
    },
    methods: {
        clearForm() {
            this.charity.charity_name = null
            this.charity.charity_number = null
            this.charity.id = null
            this.charity.distribution_primary = false
            this.charity.distribution_percentage = 0
            this.charity.distribution_backup = false
            this.charity.distribution_backup_percentage = 0
        },
        charityFetch(charityId) {
            http.get('charity_detail', { params: { id: charityId } })
                .then((response) => {
                    this.charity = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        charitySave() {
            if (this.charityId) {
                http.patch('charity_detail', this.charity)
                    .then((response) => {
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else if (this.validateForm) {
                this.charity.user = this.userId
                http.post('charity_detail', this.charity)
                    .then((response) => {
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Charity Created',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$vs.notify({
                            text: error.response.data,
                            color: 'danger',
                        })
                    })
            }
        },
    },
}
</script>
