var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Advice","subtitle":_vm.subTitle,"edit-action":true,"title-color":"#fff","subtitle-color":"#fff","content-color":"#fff","card-background":"rgba(var(--vs-secondary),0.65)"},on:{"edit":function($event){_vm.show.adviceModal = true}}},[(_vm.advice.appointment)?_c('ul',[_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-6"},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1),_c('div',{staticClass:"leading-tight"},[(_vm.upcomingAppointment(_vm.advice.appointment.date_time))?_c('p',{staticClass:"font-bold text-secondary"},[_vm._v(" Upcoming Appointment ")]):_c('p',{staticClass:"font-medium text-secondary"},[_vm._v(" Appointment Past ")]),_c('span',{staticClass:"font-xs"},[_vm._v(_vm._s(_vm.formatDateAndTime(_vm.advice.appointment.date_time)))])])])]):_vm._e(),(_vm.advice.appointment)?_c('vs-divider'):_vm._e(),_c('ul',[(
                _vm.advice.power_of_attorney !== null ||
                _vm.advice.power_of_attorney_recommended
            )?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('div',{staticClass:"font-medium"},[_vm._v("Power of Attorney")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.power_of_attorney,
                            _vm.advice.power_of_attorney_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.power_of_attorney, _vm.advice.power_of_attorney_recommended )[1]))])],1)]):_vm._e(),(
                _vm.advice.protect_home !== null ||
                _vm.advice.protect_home_recommended
            )?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("Home Protection")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.protect_home,
                            _vm.advice.protect_home_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.protect_home, _vm.advice.protect_home_recommended )[1]))])],1)]):_vm._e(),(
                _vm.advice.life_insurance !== null ||
                _vm.advice.life_insurance_recommended
            )?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("Life Insurance")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.life_insurance,
                            _vm.advice.life_insurance_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.life_insurance, _vm.advice.life_insurance_recommended )[1]))])],1)]):_vm._e(),(
                _vm.advice.life_insurance_review !== null ||
                _vm.advice.life_insurance_review_recommended
            )?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("Life Insurance Review")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.life_insurance_review,
                            _vm.advice.life_insurance_review_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.life_insurance_review, _vm.advice.life_insurance_review_recommended )[1]))])],1)]):_vm._e(),(
                _vm.advice.inheritance_tax !== null ||
                _vm.advice.inheritance_tax_recommended
            )?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("Inheritance Tax")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.inheritance_tax,
                            _vm.advice.inheritance_tax_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.inheritance_tax, _vm.advice.inheritance_tax_recommended )[1]))])],1)]):_vm._e(),(
                _vm.advice.funeral_plan !== null ||
                _vm.advice.funeral_plan_recommended
            )?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("Funeral Plan")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.funeral_plan,
                            _vm.advice.funeral_plan_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.funeral_plan, _vm.advice.funeral_plan_recommended )[1]))])],1)]):_vm._e(),(_vm.advice.business !== null || _vm.advice.business_recommended)?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("Business")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.business,
                            _vm.advice.business_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.business, _vm.advice.business_recommended )[1]))])],1)]):_vm._e(),(
                _vm.advice.assets_abroad !== null ||
                _vm.advice.assets_abroad_recommended
            )?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("Assets Abroad")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.assets_abroad,
                            _vm.advice.assets_abroad_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.assets_abroad, _vm.advice.assets_abroad_recommended )[1]))])],1)]):_vm._e(),(_vm.advice.disabled !== null || _vm.advice.disabled_recommended)?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("Disabled Beneficiaries")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.disabled,
                            _vm.advice.disabled_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.disabled, _vm.advice.disabled_recommended )[1]))])],1)]):_vm._e(),(_vm.advice.children !== null || _vm.advice.children_recommended)?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("Children")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.children,
                            _vm.advice.children_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.children, _vm.advice.children_recommended )[1]))])],1)]):_vm._e(),(_vm.advice.scotland1 !== null || _vm.advice.scotland1_recommended)?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v("Scotland - Married with Children")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.scotland1,
                            _vm.advice.scotland1_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.scotland1, _vm.advice.scotland1_recommended )[1]))])],1)]):_vm._e(),(_vm.advice.scotland2 !== null || _vm.advice.scotland2_recommended)?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v(" Scotland - Married without Children ")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.scotland2,
                            _vm.advice.scotland2_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.scotland2, _vm.advice.scotland2_recommended )[1]))])],1)]):_vm._e(),(_vm.advice.scotland3 !== null || _vm.advice.scotland3_recommended)?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v(" Scotland - Not Married with Children ")]),_c('vs-chip',{staticClass:"font-xs",class:'text-white',attrs:{"color":_vm.label(
                            _vm.advice.scotland3,
                            _vm.advice.scotland3_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.scotland3, _vm.advice.scotland3_recommended )[1])+" ")])],1)]):_vm._e(),(
                _vm.advice.right_to_occupy1 !== null ||
                _vm.advice.right_to_occupy1_recommended
            )?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v(" Right to Occupy - Leaving all residue to partner & has children ")]),_c('vs-chip',{staticClass:"font-xs text-white",attrs:{"color":_vm.label(
                            _vm.advice.right_to_occupy1,
                            _vm.advice.right_to_occupy1_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.right_to_occupy1, _vm.advice.right_to_occupy1_recommended )[1]))])],1)]):_vm._e(),(
                _vm.advice.right_to_occupy2 !== null ||
                _vm.advice.right_to_occupy2_recommended
            )?_c('li',{staticClass:"flex items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_c('vs-avatar',{staticClass:"m-0",style:({
                        background: `rgba(var(--vs-secondary),.15)`,
                    }),attrs:{"size":"35px","icon-pack":"feather","icon":"icon-crosshair"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v(" Right to Occupy - Not leaving all residue to partner ")]),_c('vs-chip',{staticClass:"font-xs text-white",attrs:{"color":_vm.label(
                            _vm.advice.right_to_occupy2,
                            _vm.advice.right_to_occupy2_recommended
                        )[0]}},[_vm._v(_vm._s(_vm.label( _vm.advice.right_to_occupy2, _vm.advice.right_to_occupy2_recommended )[1])+" ")])],1)]):_vm._e()]),(_vm.advice.notes)?_c('p',[_vm._v("Notes : "+_vm._s(_vm.advice.notes))]):_vm._e(),_c('EditAdviceModal',{attrs:{"show":_vm.show.adviceModal,"data":_vm.advice},on:{"close":function($event){_vm.show.adviceModal = false},"newData":function($event){return _vm.$emit('newData')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }