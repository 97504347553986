<template>
    <vx-card :title="'Timeline'" collapse-action>
        <ul class="vx-timeline">
            <li v-for="(log, index) in logsOrdered" :key="index">
                <div class="flex">
                    <div>
                        <div class="timeline-icon" :class="iconType(log)[1]">
                            <feather-icon
                                :icon="iconType(log)[0]"
                                svgClasses="text-white stroke-current w-5 h-5"
                            />
                        </div>
                        <div class="timeline-info fit">
                            <span class="font-semibold">{{ title(log) }} </span>

                            <span class="activity-desc">
                                <vx-tooltip
                                    :text="formatDateAndTime(log.date)"
                                    position="bottom"
                                >
                                    <span class="pointer">
                                        {{ timeDiffCat(log.date)[0] }}
                                    </span>
                                </vx-tooltip>
                            </span>
                        </div>
                        <small class="text-grey activity-e-time">{{
                            subTitle(log)
                        }}</small>
                    </div>
                </div>
            </li>
        </ul>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'TimeLineCard',
    props: ['logsBroker', 'logsSystem', 'user'],
    mixins: [textFormat, tableHelpers],
    computed: {
        logsOrdered() {
            var data = [
                ...this.logsBroker,
                ...[this.userCreated],
                ...[this.userLastLogin],
                ...this.logsSystem,
            ]
            return data.sort((a, b) => new Date(a.date) - new Date(b.date))
        },
        userCreated() {
            if (this.user)
                return {
                    type: 'user',
                    date: this.user.date_joined,
                    description: 'Account Created',
                }
            return null
        },
        userLastLogin() {
            if (this.user)
                return {
                    type: 'user',
                    date: this.user.last_login,
                    description: 'Last Login',
                }
            return null
        },
    },
    methods: {
        title(data) {
            if (data.type === 'broker_send_log') {
                var string = ''
                if (data.default_email_template || data.default_sms_template) {
                    if (data.default_email_template.description)
                        string += data.default_email_template.description
                    else if (data.default_sms_template.description)
                        string += data.default_sms_template.description
                    return string
                }
            } else if (data.type === 'user') return data.description
            else if (data.type === 'system_send_log') {
                if (data.email_template) return data.email_template.description
                else if (data.sms_template) return data.sms_template.description
            }
            return ''
        },
        subTitle(data) {
            var string = ''
            if (data.type === 'broker_send_log') {
                if (data.broker) string += 'Sent by ' + data.broker.company_name
                if (data.broker && data.batch)
                    string += " in batch '" + data.batch.description + "'"
                if (data.client && data.client.coupon)
                    string += ' code : ' + data.client.coupon
                return string
            } else if (data.type === 'system_send_log') {
                string = 'Sent by MyLastWill'
                return string
            }

            return ''
        },
        iconType(data) {
            if (data && data.type === 'broker_send_log')
                return ['MailIcon', 'bg-success']
            if (data && data.type === 'system_send_log') {
                if (data.email_template) return ['MailIcon', 'bg-success']
                if (data.sms_template)
                    return ['MessageSquareIcon', 'bg-warning']
            }
            if (data && data.type === 'user') return ['UserIcon', 'bg-primary']
            return ['FileIcon', 'bg-primary']
        },
    },
}
</script>

<style scoped>
.fit {
    width: fit-content;
}
</style>
