<template>
    <div>
        <h5 class="pb-6">{{ cat }}</h5>
        <ul
            v-if="
                (people && people.length) ||
                (groups && groups.length) ||
                (charities && charities.length)
            "
        >
            <li
                class="flex justify-aroun items-cente mb-4"
                v-for="(person, index) in people"
                :key="index"
            >
                <div class="mr-2">
                    <vs-avatar class="m-0" size="35px" />
                </div>

                <div class="">
                    <p v-show="!edit" class="font-medium">
                        {{ person.full_name }}
                    </p>
                    <vs-input
                        v-show="edit"
                        class="w-full"
                        type="text"
                        :value="person.full_name"
                        @input="
                            $emit('editPerson', {
                                id: person.id,
                                full_name: $event,
                            })
                        "
                    />

                    <p class="text-xs">
                        {{ person.relationship
                        }}<span
                            v-if="
                                cat === 'Children' &&
                                (person.child || person.child_step)
                            "
                        >
                            <br />
                            {{ person.child_born_to }}<br />{{
                                ageWrapper(person)
                            }}</span
                        >
                    </p>
                </div>

                <div v-show="!edit" class="ml-auto">
                    <vs-chip v-if="cat !== 'All'">{{ label(person) }}</vs-chip>

                    <vs-chip
                        v-else-if="
                            person.distribution_sole_primary ||
                            person.distribution_primary ||
                            person.distribution_backup
                        "
                    >
                        Included
                    </vs-chip>

                    <vs-chip
                        v-else-if="
                            (person.partner || person.child) &&
                            !person.distribution_sole_primary &&
                            !person.distribution_primary &&
                            !person.distribution_backup
                        "
                        color="secondary"
                        >Excluded
                    </vs-chip>
                </div>
                <div v-show="!edit" class="cursor-pointer">
                    <vs-button
                        :disabled="locked"
                        v-on:click="$emit('setSelectedPerson', person.id)"
                        radius
                        type="flat"
                        icon-pack="feather"
                        icon="icon-edit-2"
                    />
                </div>
            </li>
            <li
                class="flex justify-around items-center mb-4"
                v-for="(group, index) in groups"
                :key="index + 'group'"
            >
                <div class="mr-2">
                    <vs-avatar class="m-0" size="35px" />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">
                        {{ capitalise(group.group_name) }}
                    </p>
                </div>
                <div class="ml-auto">
                    <vs-chip v-if="cat !== 'All'">{{
                        labelGroup(group)
                    }}</vs-chip>

                    <vs-chip
                        v-else-if="
                            group.distribution_primary ||
                            group.distribution_backup
                        "
                    >
                        Included
                    </vs-chip>
                </div>
                <div class="cursor-pointer">
                    <vs-button
                        :disabled="locked"
                        v-on:click="$emit('setSelectedGroup', group.id)"
                        radius
                        type="flat"
                        icon-pack="feather"
                        icon="icon-edit-2"
                    />
                </div>
            </li>
            <li
                class="flex justify-around items-center mb-4"
                v-for="(charity, index) in charities"
                :key="index + 'charity'"
            >
                <div class="mr-2">
                    <vs-avatar class="m-0" size="35px" />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">
                        {{ capitalise(charity.charity_name) }}
                    </p>
                </div>
                <div class="ml-auto">
                    <vs-chip v-if="cat !== 'All'">{{
                        labelCharity(charity)
                    }}</vs-chip>

                    <vs-chip
                        v-else-if="
                            charity.distribution_primary ||
                            charity.distribution_backup
                        "
                    >
                        Included
                    </vs-chip>
                </div>
                <div class="cursor-pointer">
                    <vs-button
                        :disabled="locked"
                        v-on:click="$emit('setSelectedCharity', charity.id)"
                        radius
                        type="flat"
                        icon-pack="feather"
                        icon="icon-edit-2"
                    />
                </div>
            </li>
        </ul>
        <p v-else>No {{ cat }}</p>
    </div>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'PeopleList',
    props: [
        'people',
        'groups',
        'charities',
        'cat',
        'locked',
        'edit',
        'personalDetails',
    ],
    mixins: [textFormat],
    methods: {
        label(person) {
            if (this.cat === 'Children') {
                if (person.child && !person.child_step) return 'Child'
                else if (person.child_step) return 'Step Child'
            } else if (this.cat === 'Executors') {
                if (person.executor_sole_primary) return 'Sole'
                else if (person.executor_primary) return 'Primary'
                else if (person.executor_secondary) return 'Secondary'
                else if (person.executor_backup) return 'Backup'
            } else if (this.cat === 'Trustees') {
                if (person.trustee_sole_primary) return 'Sole'
                else if (person.trustee_primary) return 'Primary'
                else if (person.trustee_secondary) return 'Secondary'
                else if (person.trustee_backup) return 'Backup'
            } else if (this.cat === 'Guardians') {
                if (person.guardian_sole_primary) return 'Sole'
                else if (person.guardian_primary) return 'Primary'
                else if (person.guardian_secondary) return 'Secondary'
                else if (person.guardian_backup) return 'Backup'
            } else if (this.cat === 'Beneficairies') {
                if (person.distribution_sole_primary) return 'Sole'
                else if (person.distribution_primary)
                    return `Primary ${person.distribution_primary_percentage}%`
                else if (person.distribution_backup)
                    return `Backup ${person.distribution_backup_percentage}%`
            } else if (this.cat === 'Share') {
                return 'Share'
            } else if (this.cat === 'Occupants') {
                if (this.personalDetails)
                    return (
                        'Occupant at ' +
                        this.personalDetails.address_line_1 +
                        ', ' +
                        this.personalDetails.post_code
                    )
                return 'Occupant'
            }
        },
        labelGroup(group) {
            if (this.cat === 'Beneficairies') {
                if (group.distribution_primary)
                    return `Primary ${group.distribution_percentage}%`
                else if (group.distribution_backup)
                    return `Backup ${group.distribution_backup_percentage}%`
            }
            if (this.cat === 'Children') {
                if (
                    this.personalDetails &&
                    this.personalDetails.children === 'expecting'
                )
                    return 'Expecting'
            }
        },
        labelCharity(charity) {
            if (this.cat === 'Beneficairies') {
                if (charity.distribution_primary)
                    return `Primary ${charity.distribution_percentage}%`
                else if (charity.distribution_backup)
                    return `Backup ${charity.distribution_backup_percentage}%`
            }
        },
        ageWrapper(person) {
            if (person.dob_year && person.dob_month && person.dob_day) {
                return (
                    this.age(
                        new Date(
                            person.dob_year,
                            Number(person.dob_month) - 1,
                            person.dob_day
                        )
                    ) + ' Years old'
                )
            } else {
                return null
            }
        },
    },
}
</script>
