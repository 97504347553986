<template>
    <vx-card
        title="Coupons"
        :subtitle="subTitle"
        collapse-action
        startCollapsed
        class="showOverflow"
    >
        <template slot="no-body">
            <ul class="tasks-today-container">
                <li
                    class="px-6 py-4 tasks-today__task"
                    v-for="(coupon, index) in coupons"
                    :key="index"
                >
                    <div class="vx-row">
                        <div class="vx-col w-full sm:w-auto">
                            <div class="font-semibold text-lg flex mb-2">
                                <span class="mr-6">
                                    {{ coupon.code }}
                                </span>

                                <small
                                    v-if="coupon.price_discount_amount"
                                    class="text-grey"
                                >
                                    £{{
                                        coupon.price_discount_amount / 100
                                    }}
                                    off</small
                                >
                                <small
                                    v-else-if="coupon.price_discount_percentage"
                                    class="text-grey"
                                >
                                    {{ coupon.price_discount_percentage }}%
                                    off</small
                                >
                                <span v-else>
                                    <small
                                        v-if="
                                            coupon.price_fixed_single !==
                                                null ||
                                            typeof coupon.price_fixed_single !==
                                                'undefined'
                                        "
                                        class="text-grey"
                                    >
                                        £{{
                                            coupon.price_fixed_single / 100
                                        }}</small
                                    >

                                    <small
                                        v-if="
                                            coupon.price_fixed_joint !== null ||
                                            typeof coupon.price_fixed_joint !==
                                                'undefined'
                                        "
                                        class="text-grey"
                                    >
                                        or £{{
                                            coupon.price_fixed_joint / 100
                                        }}</small
                                    >
                                </span>
                            </div>
                            <vs-chip
                                v-if="coupon.broker && !coupon.partner_code"
                                >{{ coupon.broker.company_name }}</vs-chip
                            >
                            <vs-chip
                                v-else-if="
                                    coupon.charity && !coupon.partner_code
                                "
                                >{{ coupon.charity.name }}</vs-chip
                            >
                            <vs-chip v-if="coupon.applied" :color="'success'"
                                >Applied</vs-chip
                            >
                            <vs-chip v-if="checkDate(coupon)">Expired</vs-chip>
                            <vs-chip v-if="coupon.used">Used</vs-chip>
                            <vs-chip v-if="coupon.partner_code"
                                >Partner Code</vs-chip
                            >
                        </div>
                        <div
                            class="tasks-today__actions vx-col w-full sm:w-auto ml-auto mt-2 sm:mt-0"
                        >
                            <vx-tooltip text="Apply Coupon">
                                <vs-button
                                    v-on:click="applyCoupon(coupon)"
                                    radius
                                    color="primary"
                                    type="flat"
                                    icon-pack="feather"
                                    icon="icon-link"
                                ></vs-button>
                            </vx-tooltip>
                            <vs-button
                                v-on:click="showCouponModal(coupon)"
                                radius
                                color="primary"
                                type="flat"
                                icon-pack="feather"
                                icon="icon-edit-2"
                                class="ml-3"
                            ></vs-button>
                        </div>
                    </div>
                </li>
            </ul>
            <CouponModal
                :show="show.couponModal"
                :coupon="selectedCoupon"
                @close="clearCoupon"
                @closeModal="closeCouponModal"
                @refresh="$emit('newData')"
            ></CouponModal>
        </template>
        <p v-if="!coupons.length">No coupons</p>
        <vs-divider></vs-divider>

        <div class="flex items-start">
            <div class="w-full">
                <CouponSearch @selectedCoupon="setCoupon" />
            </div>
            <div class="flex flex-col self-end ml-3">
                <vx-tooltip text="Apply Coupon" class="self-center">
                    <vs-button
                        :disabled="!newCoupon"
                        v-on:click="applyCoupon(newCoupon)"
                        radius
                        color="primary"
                        type="flat"
                        icon-pack="feather"
                        icon="icon-link"
                    ></vs-button>
                </vx-tooltip>
            </div>
        </div>
    </vx-card>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

import CouponSearch from '@/components/common/CouponSearch'
import CouponModal from '@/views/pages/System/Coupons/All/CouponModal'

export default {
    name: 'CouponsCard',
    props: ['couponsData', 'userId'],
    mixins: [textFormat],
    components: {
        CouponModal,
        CouponSearch,
    },
    data() {
        return {
            show: {
                couponModal: false,
            },
            selectedCoupon: null,
            newCoupon: null,
        }
    },
    computed: {
        subTitle() {
            if (this.coupons)
                return `${this.coupons.length} Associated ${
                    this.coupons.length === 1 ? 'coupon' : 'coupons'
                }`
            return ''
        },
        coupons() {
            return this.couponsData
        },
    },
    methods: {
        setCoupon(coupon) {
            if (coupon) this.newCoupon = coupon
            else this.newCoupon = null
        },
        applyCoupon(coupon) {
            if (coupon && coupon.code && this.userId) {
                var data = {
                    code: coupon.code,
                    user_id: this.userId,
                }
                http.post('apply_user_coupon', data)
                    .then((response) => {
                        this.$emit('newData')
                        this.$vs.notify({
                            text: 'Coupon applied',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        checkDate(coupon) {
            var today = new Date()
            var expiryDate = new Date(coupon.expiry_date)
            if (expiryDate < today) return true
            return false
        },
        showCouponModal(coupon) {
            this.show.couponModal = true
            this.selectedCoupon = coupon.code
        },
        clearCoupon() {
            this.closeCouponModal()
            this.selectedCoupon = null
        },
        closeCouponModal() {
            this.show.couponModal = false
        },
    },
}
</script>

<style lang="scss">
.tasks-today-container {
    .tasks-today__task {
        transition: background 0.15s ease-out;
        .tasks-today__actions {
            display: none;
        }

        &:hover {
            background: rgba(var(--vs-primary), 0.04);
            .tasks-today__actions {
                display: flex;
            }
        }
    }
}
.showOverflow {
    overflow: visible;
}
</style>
