<template>
    <vx-card
        :title="user.email"
        :edit-action="!locked"
        @edit="edit"
        collapse-action
        title-color="#fff"
        subtitle-color="#fff"
        content-color="#fff"
        card-background="rgba(var(--vs-primary),0.65)"
    >
        <ul class="friend-suggesions-list">
            <vs-alert
                v-if="userAccount && userAccount.change_password"
                class="mb-4"
                >Password Not Set</vs-alert
            >
            <vs-alert
                v-if="userAccount && userAccount.temp_account"
                class="mb-4"
                >Temporary Broker Account by {{ userAccount.broker }}</vs-alert
            >
            <li class="friend-suggestion flex items-center mb-4">
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-user"
                        :style="{
                            background: `rgba(var(--vs-primary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">{{ user.details.full_name }}</p>
                    <span class="text-xs">{{
                        user.details.marital_status
                    }}</span>
                </div>
                <div class="ml-auto cursor-pointer self-center">
                    <vs-button
                        v-if="!hideUserLink"
                        v-on:click="
                            $router.push({
                                name: 'user',
                                params: { id: user.details.user },
                            })
                        "
                        radius
                        type="flat"
                        icon-pack="feather"
                        icon="icon-chevron-right"
                        color="#fff"
                    />
                </div>
            </li>

            <li v-if="user.details.marital_status !== 'Single'">
                <div class="friend-suggestion flex items-center mb-4">
                    <div class="mr-3">
                        <vs-avatar
                            class="m-0"
                            size="35px"
                            icon-pack="feather"
                            icon="icon-users"
                            :style="{
                                background: `rgba(var(--vs-primary),.15)`,
                            }"
                        />
                    </div>
                    <div class="leading-tight flex justify-between w-full">
                        <div class="w-full">
                            <p v-if="user.details.partner" class="font-medium">
                                {{ user.details.partner.full_name }}
                            </p>
                            <p v-else class="font-medium">
                                {{
                                    user.details.marital_status === 'Married'
                                        ? 'Spouse'
                                        : 'Partner'
                                }}
                            </p>
                            <p v-if="!user.details.partner" class="text-xs">
                                Account not created
                            </p>
                            <p v-else class="text-xs">
                                {{
                                    user.details.marital_status === 'Married'
                                        ? 'Spouse'
                                        : 'Partner'
                                }}
                            </p>
                            <div v-if="show.linkPartner" class="flex items-end">
                                <UserSelect
                                    class="w-3/4 mr-2 mt-3"
                                    @selectedUser="setPartner"
                                    :white="true"
                                ></UserSelect>
                                <vs-button
                                    @click="savePartner"
                                    radius
                                    color="#fff"
                                    type="flat"
                                    icon-pack="feather"
                                    icon="icon-save"
                                ></vs-button>
                            </div>
                        </div>
                        <div v-if="!user.details.partner" class="ml-auto">
                            <vx-tooltip text="Link Partner">
                                <vs-button
                                    @click="
                                        show.linkPartner = !show.linkPartner
                                    "
                                    radius
                                    color="primary"
                                    type="flat"
                                    icon-pack="feather"
                                    icon="icon-link"
                                    text-color="#fff"
                                ></vs-button>
                            </vx-tooltip>
                        </div>
                    </div>

                    <div
                        v-if="user.details.partner"
                        class="ml-auto cursor-pointer self-center"
                    >
                        <vs-button
                            v-on:click="
                                $router.push({
                                    name: 'user',
                                    params: {
                                        id: user.details.partner.user_id,
                                    },
                                })
                            "
                            radius
                            type="flat"
                            icon-pack="feather"
                            icon="icon-chevron-right"
                            color="#fff"
                        />
                    </div>
                </div>
            </li>

            <li class="friend-suggestion flex items-center mb-4">
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-home"
                        :style="{
                            background: `rgba(var(--vs-primary),.15)`,
                        }"
                    />
                </div>
                <div class="flex justify-between w-full">
                    <div class="leading-tight">
                        <div v-if="user.details.country" class="font-medium">
                            <p>{{ user.details.country }}</p>
                        </div>
                        <span
                            v-if="user.details.address_line_1"
                            class="text-xs"
                        >
                            {{ user.details.address_line_1 }}
                            <br />
                        </span>
                        <span
                            v-if="user.details.address_line_2"
                            class="text-xs"
                        >
                            {{ user.details.address_line_2 }}
                            <br />
                        </span>
                        <span v-if="user.details.post_town" class="text-xs">
                            {{ user.details.post_town }}
                            <br />
                        </span>
                        <span v-if="user.details.post_code" class="text-xs">{{
                            user.details.post_code
                        }}</span>
                    </div>
                    <div>
                        <vs-chip v-if="user.details.moving" color="primary"
                            >Currently Moving Home</vs-chip
                        >
                    </div>
                </div>
            </li>
            <li class="friend-suggestion flex items-center">
                <div class="mr-3">
                    <vs-avatar
                        class="m-0"
                        size="35px"
                        icon-pack="feather"
                        icon="icon-phone"
                        :style="{
                            background: `rgba(var(--vs-primary),.15)`,
                        }"
                    />
                </div>
                <div class="leading-tight">
                    <p class="font-medium">{{ user.details.phone }}</p>
                    <p class="text-xs">{{ user.email }}</p>
                </div>
            </li>
        </ul>
        <PersonalDetailsModal
            :personalDetails="user.details"
            :show="show.detailsModal"
            @close="closePersonalDetailsModal"
            @changed="$emit('changed')"
            @newData="$emit('newData', $event)"
        ></PersonalDetailsModal>
    </vx-card>
</template>

<script>
import PersonalDetailsModal from '../Modals/PersonalDetailsModal'
import UserSelect from '@/components/common/UserSelect'
import { http } from '@/services'

export default {
    name: 'PersonalDetailsCard',
    props: {
        user: {
            type: Object,
        },
        locked: {
            type: Boolean,
            default: false,
        },
        hideUserLink: {
            type: Boolean,
            default: false,
        },
        userAccount: {
            type: Object,
        },
    },
    computed: {
        userStore() {
            return this.$store.getters.user
        },
    },
    components: {
        PersonalDetailsModal,
        UserSelect,
    },
    data() {
        return {
            show: {
                detailsModal: false,
                linkPartner: false,
            },
            form: {
                id: null,
                partner: null,
            },
        }
    },
    methods: {
        edit() {
            this.show.detailsModal = true
        },
        closePersonalDetailsModal() {
            this.show.detailsModal = false
        },
        setPartner(value) {
            this.form.partner = value.id
        },
        savePartner() {
            if (!this.form.partner) return
            this.form.id = this.user.details.id
            http.patch('personal_details', this.form)
                .then((response) => {
                    this.$emit('newData', response.data)
                    this.$emit('changed')
                    this.$vs.notify({
                        text: 'Partner Saved',
                        color: 'success',
                    })
                    this.show.linkPartner = false
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        },
    },
}
</script>
