var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',{staticClass:"pb-6"},[_vm._v(_vm._s(_vm.cat))]),(
            (_vm.people && _vm.people.length) ||
            (_vm.groups && _vm.groups.length) ||
            (_vm.charities && _vm.charities.length)
        )?_c('ul',[_vm._l((_vm.people),function(person,index){return _c('li',{key:index,staticClass:"flex justify-aroun items-cente mb-4"},[_c('div',{staticClass:"mr-2"},[_c('vs-avatar',{staticClass:"m-0",attrs:{"size":"35px"}})],1),_c('div',{},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.edit),expression:"!edit"}],staticClass:"font-medium"},[_vm._v(" "+_vm._s(person.full_name)+" ")]),_c('vs-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.edit),expression:"edit"}],staticClass:"w-full",attrs:{"type":"text","value":person.full_name},on:{"input":function($event){return _vm.$emit('editPerson', {
                            id: person.id,
                            full_name: $event,
                        })}}}),_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(person.relationship)),(
                            _vm.cat === 'Children' &&
                            (person.child || person.child_step)
                        )?_c('span',[_c('br'),_vm._v(" "+_vm._s(person.child_born_to)),_c('br'),_vm._v(_vm._s(_vm.ageWrapper(person)))]):_vm._e()])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.edit),expression:"!edit"}],staticClass:"ml-auto"},[(_vm.cat !== 'All')?_c('vs-chip',[_vm._v(_vm._s(_vm.label(person)))]):(
                        person.distribution_sole_primary ||
                        person.distribution_primary ||
                        person.distribution_backup
                    )?_c('vs-chip',[_vm._v(" Included ")]):(
                        (person.partner || person.child) &&
                        !person.distribution_sole_primary &&
                        !person.distribution_primary &&
                        !person.distribution_backup
                    )?_c('vs-chip',{attrs:{"color":"secondary"}},[_vm._v("Excluded ")]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.edit),expression:"!edit"}],staticClass:"cursor-pointer"},[_c('vs-button',{attrs:{"disabled":_vm.locked,"radius":"","type":"flat","icon-pack":"feather","icon":"icon-edit-2"},on:{"click":function($event){return _vm.$emit('setSelectedPerson', person.id)}}})],1)])}),_vm._l((_vm.groups),function(group,index){return _c('li',{key:index + 'group',staticClass:"flex justify-around items-center mb-4"},[_c('div',{staticClass:"mr-2"},[_c('vs-avatar',{staticClass:"m-0",attrs:{"size":"35px"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.capitalise(group.group_name))+" ")])]),_c('div',{staticClass:"ml-auto"},[(_vm.cat !== 'All')?_c('vs-chip',[_vm._v(_vm._s(_vm.labelGroup(group)))]):(
                        group.distribution_primary ||
                        group.distribution_backup
                    )?_c('vs-chip',[_vm._v(" Included ")]):_vm._e()],1),_c('div',{staticClass:"cursor-pointer"},[_c('vs-button',{attrs:{"disabled":_vm.locked,"radius":"","type":"flat","icon-pack":"feather","icon":"icon-edit-2"},on:{"click":function($event){return _vm.$emit('setSelectedGroup', group.id)}}})],1)])}),_vm._l((_vm.charities),function(charity,index){return _c('li',{key:index + 'charity',staticClass:"flex justify-around items-center mb-4"},[_c('div',{staticClass:"mr-2"},[_c('vs-avatar',{staticClass:"m-0",attrs:{"size":"35px"}})],1),_c('div',{staticClass:"leading-tight"},[_c('p',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.capitalise(charity.charity_name))+" ")])]),_c('div',{staticClass:"ml-auto"},[(_vm.cat !== 'All')?_c('vs-chip',[_vm._v(_vm._s(_vm.labelCharity(charity)))]):(
                        charity.distribution_primary ||
                        charity.distribution_backup
                    )?_c('vs-chip',[_vm._v(" Included ")]):_vm._e()],1),_c('div',{staticClass:"cursor-pointer"},[_c('vs-button',{attrs:{"disabled":_vm.locked,"radius":"","type":"flat","icon-pack":"feather","icon":"icon-edit-2"},on:{"click":function($event){return _vm.$emit('setSelectedCharity', charity.id)}}})],1)])})],2):_c('p',[_vm._v("No "+_vm._s(_vm.cat))])])
}
var staticRenderFns = []

export { render, staticRenderFns }