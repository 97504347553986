<template>
    <!-- compose email -->
    <vs-prompt
        title="Account"
        accept-text="Save"
        @cancel="close"
        @accept="save"
        @close="close"
        :is-valid="validateForm"
        :active.sync="showModal"
    >
        <VuePerfectScrollbar
            class="scroll-area p-4"
            :settings="settings"
            :key="$vs.rtl"
        >
            <form
                @submit.prevent
                id="loading-container"
                class="vs-con-loading__container"
            >
                <vs-input
                    name="mailTo"
                    label-placeholder="Username"
                    v-model="accountData.email"
                    class="w-full mb-6"
                    :danger="!validateEmail"
                    :danger-text="errors.email"
                    val-icon-danger="clear"
                    :success="validateEmail"
                    val-icon-success="done"
                    :color="validateEmail ? 'success' : 'danger'"
                />
                <vs-input
                    name="password"
                    label-placeholder="New Password"
                    v-model="accountData.password"
                    :danger="!validatePassword"
                    :success="validatePassword"
                    :val-icon-success="
                        this.accountData.password ? 'done' : 'clear'
                    "
                    val-icon-danger="clear"
                    class="w-full mb-6"
                />
                <vs-input
                    name="password2"
                    label-placeholder="New Password Confirm"
                    v-model="accountData.password2"
                    class="w-full mb-6"
                    :danger="!validatePassword2"
                    :success="validatePassword2"
                    :val-icon-success="
                        this.accountData.password2 ? 'done' : 'clear'
                    "
                    val-icon-danger="clear"
                />
            </form>
        </VuePerfectScrollbar>
    </vs-prompt>
</template>

<script>
import { http } from '@/services'
import { functions } from '@/mixins/functions'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    name: 'UsernamePasswordChangeModal',
    props: ['show', 'user'],
    mixins: [functions],
    components: {
        VuePerfectScrollbar,
    },
    watch: {
        user: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.accountData.id = value.id
                    this.accountData.email = value.email
                }
            },
        },
    },
    computed: {
        showModal: {
            get() {
                return this.show
            },
            set(value) {
                // this.$emit('show', value)
            },
        },
        validateEmail() {
            return this.validEmail(this.accountData.email) && !this.errors.email
        },
        validateForm() {
            if (this.validateEmail) {
                if (this.accountData.password) {
                    if (this.validatePassword && this.validatePassword2)
                        return true
                    return false
                } else {
                    return true
                }
            }
            return false
        },
        validatePassword() {
            if (
                this.accountData.password !== '' &&
                this.accountData.password.length > 4
            )
                return true
            else if (!this.accountData.password) return true
            return false
        },
        validatePassword2() {
            if (this.accountData.password === this.accountData.password2)
                return true
            return false
        },
    },
    data() {
        return {
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            accountData: {
                id: null,
                email: null,
                password: '',
                password2: '',
            },
            errors: {
                email: null,
                password: null,
            },
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        clearErrors() {
            this.errors.email = null
            this.errors.password = null
        },
        clearForm() {
            this.accountData.password = ''
            this.accountData.password2 = ''
        },
        save() {
            this.clearErrors()
            this.$vs.loading({
                background: this.backgroundLoading,
                color: this.colorLoading,
                container: '#loading-container',
                scale: 0.45,
            })

            http.patch('user_username_password', this.accountData)
                .then((response) => {
                    this.$emit('close')
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                    this.$emit('refresh')
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Account saved',
                        icon: 'feather',
                        iconPack: 'icon-check',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    if (error.response.data.email)
                        this.errors.email = error.response.data.email[0]
                    else this.error.email = null
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                })
        },
    },
}
</script>
