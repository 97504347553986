<template>
    <SideBarModal :title="groupName" :show="show" @close="$emit('close')">
        <template v-if="group" v-slot:content>
            <vs-collapse accordion open>
                <div class="vx-row mb-2">
                    <div class="vx-col w-full">
                        <vs-input
                            class="w-full"
                            :danger="!group.group_name"
                            label-placeholder="Group Name"
                            v-model="group.group_name"
                        />
                    </div>
                </div>

                <vs-collapse-item open>
                    <div slot="header">Distribution</div>

                    <div class="flex mb-3">
                        <div class="w-1/2 object-left">
                            <vs-checkbox v-model="group.distribution_primary"
                                >Primary</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="flex flex-row mb-3">
                        <div class="w-2/3">
                            <div class="vx-col w-2/3">
                                <vs-input
                                    label-placeholder="Percentage"
                                    v-model="group.distribution_percentage"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="rounded-lg p-3 bg-grey-light">
                        <h6 class="mb-3">Primary backup option</h6>
                        <div class="flex flex-row mb-3">
                            <div class="w-full">
                                <vs-checkbox
                                    v-model="
                                        group.distribution_backup_one_children
                                    "
                                    >Children
                                </vs-checkbox>
                            </div>
                        </div>
                        <div class="flex flex-row mb-3">
                            <div class="w-full">
                                <vs-checkbox
                                    v-model="
                                        group.distribution_backup_one_remaining_group
                                    "
                                    >Remaining group
                                </vs-checkbox>
                            </div>
                        </div>
                    </div>

                    <vs-divider></vs-divider>

                    <div class="vx-row mb-6">
                        <div class="vx-col w-1/2">
                            <vs-checkbox v-model="group.distribution_backup"
                                >Backup</vs-checkbox
                            >
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <div class="w-2/3">
                            <vs-input
                                class="w-full"
                                label-placeholder="Percentage"
                                v-model="group.distribution_backup_percentage"
                            />
                        </div>
                    </div>
                </vs-collapse-item>
            </vs-collapse>
        </template>
        <template v-slot:buttons>
            <vs-button :disabled="!validateForm" class="mr-6" @click="groupSave"
                >Save
            </vs-button>
            <vs-button type="border" color="danger" @click="$emit('close')"
                >Cancel
            </vs-button>
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'
import { TextFormat } from '@/mixins/TextFormat'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'GroupModal',
    components: {
        SideBarModal,
    },
    props: ['groupId', 'show', 'userId'],
    watch: {
        groupId: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.groupFetch(value)
                }
            },
        },
    },
    data() {
        return {
            group: {
                group_name: null,
                distribution_primary: false,
                distribution_percentage: 0,
                distribution_backup_one_children: false,
                distribution_backup_one_remaining_group: false,
                distribution_backup: false,
                distribution_backup_percentage: 0,
            },
        }
    },
    computed: {
        validateForm() {
            if (this.group.group_name) return true
            return false
        },
        groupName() {
            if (this.groupId) return 'Edit Group'
            return 'Create Group'
        },
    },
    methods: {
        groupFetch(groupId) {
            http.get('group_detail', { params: { id: groupId } })
                .then((response) => {
                    this.group = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        groupSave() {
            if (this.groupId) {
                http.patch('group_detail', this.group)
                    .then((response) => {
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else if (this.validateForm) {
                this.group.user = this.userId
                http.post('group_detail', this.group)
                    .then((response) => {
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Group Created',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$vs.notify({
                            text: error.response.data,
                            color: 'danger',
                        })
                    })
            }
        },
    },
}
</script>
