<template>
    <vx-card
        title="Payments"
        collapse-action
        :subtitle="subtitle"
        startCollapsed
    >
        <vs-collapse v-if="gocardless" accordion>
            <vs-collapse-item>
                <div slot="header">
                    <h5>Payments</h5>
                </div>
                <vs-list v-if="gocardless.payments" class="mb-6">
                    <vs-list-item
                        v-for="(payment, index) in gocardless.payments"
                        :key="index"
                        :title="'£' + payment.amount / 100"
                        :subtitle="
                            'Created : ' + formatDateAndTime(payment.created_at)
                        "
                    >
                        <vs-chip v-if="payment.amount_refunded" color="warning"
                            >Refunded :
                            {{ '£' + payment.amount_refunded / 100 }}
                        </vs-chip>
                        <vs-chip :color="status(payment.status)">
                            {{ payment.status.replace('_', ' ') }}</vs-chip
                        >
                    </vs-list-item>
                </vs-list>
            </vs-collapse-item>
            <vs-collapse-item v-if="gocardless.subscriptions.length">
                <div slot="header">
                    <h5>Subscriptions</h5>
                </div>
                <vs-list>
                    <vs-list-item
                        v-for="(subscription,
                        index) in gocardless.subscriptions"
                        :key="index"
                        :title="'£' + subscription.amount / 100"
                        :subtitle="
                            'Created : ' +
                            formatDateAndTime(subscription.created_at)
                        "
                    >
                        <vs-chip :color="status(subscription.status)"
                            >{{ subscription.status.replace('_', ' ') }}
                        </vs-chip>
                    </vs-list-item>
                </vs-list>
            </vs-collapse-item>
        </vs-collapse>
        <div class="flex justify-end">
            <vs-dropdown class="mr-3">
                <vs-button class="btn-drop" type="filled" icon="expand_more"
                    >Request</vs-button
                >
                <vs-dropdown-menu>
                    <vs-dropdown-item v-on:click="printingSendConfirm">
                        Printing
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-on:click="show.subscriptionModal = true"
                    >
                        Subscription
                    </vs-dropdown-item>
                    <vs-dropdown-item v-on:click="mandateSendConfirm">
                        Mandate Setup
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-on:click="show.customAmountModal = true"
                    >
                        Custom Amount
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>

            <vs-dropdown>
                <vs-button class="btn-drop" type="filled" icon="expand_more"
                    >Cancel</vs-button
                >
                <vs-dropdown-menu>
                    <vs-dropdown-item v-on:click="cancelSubscriptionConfirm"
                        >Cancel Subscription</vs-dropdown-item
                    >
                    <vs-dropdown-item @click="cancelMandateConfirm"
                        >Cancel GoCardless Payments/Mandate
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <GocardlessCustomAmount
            :show="show.customAmountModal"
            :user-id="userId"
            @close="show.customAmountModal = false"
        />
        <GocardlessSubscriptionModal
            :show="show.subscriptionModal"
            :user-id="userId"
            @close="show.subscriptionModal = false"
        />
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { http } from '@/services'
import GocardlessCustomAmount from '../Models/GocardlessCustomAmount'
import GocardlessSubscriptionModal from '../Models/GocardlessSubscription'

export default {
    name: 'GocardlessCard',
    mixins: [textFormat],
    props: ['gocardless', 'userId'],
    components: {
        GocardlessCustomAmount,
        GocardlessSubscriptionModal,
    },
    computed: {
        subtitle() {
            var string = ''
            var sub = ''
            var payments = ''
            if (this.gocardless && this.gocardless.subscriptions) {
                sub = `${this.gocardless.subscriptions.length} ${
                    this.gocardless.subscriptions.length === 1
                        ? 'Subscription'
                        : 'Subscriptions'
                }`
            }
            if (this.gocardless && this.gocardless.payments) {
                payments = `${this.gocardless.payments.length} ${
                    this.gocardless.payments.length === 1
                        ? 'Payment'
                        : 'Payments'
                }`
            }
            if (sub) string += sub
            if (sub && payments) string += ' and '
            if (payments) string += payments
            return string
        },
    },
    data() {
        return {
            show: {
                customAmountModal: false,
                subscriptionModal: false,
            },
        }
    },
    methods: {
        mandateSendConfirm() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: `Send Mandate Invitation`,
                acceptText: 'Send',
                text:
                    'Send email request to setup a go-cardless mandate.  This can then be used on gocardless website to ' +
                    'create custom payments or custom subscription',
                accept: this.sendMandateInvitation,
            })
        },
        printingSendConfirm() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: `Send Printing Invitation`,
                acceptText: 'Send',
                text:
                    'Send email request to setup a go-cardless payment for printing. \n\n This will mark the users latest will ' +
                    'as printing required on successful payment.',
                accept: this.sendPrintingInivation,
            })
        },
        subscriptionSendConfirm() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: `Send Subscription Invitation`,
                acceptText: 'Send',
                text:
                    'Send email request to setup a go-cardless subscription.  This will add subscription on successful payment',
                accept: this.sendSubscriptionInivation,
            })
        },
        cancelSubscriptionConfirm() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: `Cancel Subscription`,
                acceptText: 'Cancel',
                text: 'Cancel users active subscription',
                accept: this.cancelSubscription,
            })
        },
        cancelMandateConfirm() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: `Cancel GoCardless Mandate`,
                acceptText: 'Cancel',
                text:
                    'Cancels all upcoming payments, subscriptions and bank mandates',
                accept: this.cancelMandate,
            })
        },
        sendMandateInvitation() {
            var data = { user_pk: this.userId }
            http.post('send_mandate_invitation', data)
                .then((response) => {
                    this.$vs.notify({
                        text: 'Invitation sent',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: 'Invitation failed',
                        color: 'secondary',
                    })
                })
        },
        sendSubscriptionInivation() {
            var data = { user_pk: this.userId }
            http.post('send_subscription_invitation', data)
                .then((response) => {
                    this.$vs.notify({
                        text: 'Invitation sent',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: 'Inivation failed',
                        color: 'secondary',
                    })
                })
            this.show.subscriptionModal = false
        },
        sendPrintingInivation() {
            var data = { user_pk: this.userId }
            http.post('send_printing_invitation', data)
                .then((response) => {
                    this.$vs.notify({
                        text: 'Invitation sent',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: 'Invitation failed',
                        color: 'secondary',
                    })
                })
            this.show.printingModal = false
        },
        cancelSubscription() {
            var data = { user_pk: this.userId }
            http.post('cancel_subscription', data)
                .then((response) => {
                    this.$vs.notify({
                        text: 'Subscription cancelled',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: 'Cancellation failed',
                        color: 'secondary',
                    })
                })
        },
        cancelMandate() {
            var data = { user_pk: this.userId }
            http.post('cancel_gocardless_mandate', data)
                .then((response) => {
                    this.$vs.notify({
                        text: 'GoCardless mandate cancelled',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: 'Cancellation failed',
                        color: 'secondary',
                    })
                })
        },
        status(payment) {
            var colour = 'primary'
            switch (payment) {
                case 'pending_submission':
                    colour = 'warning'
                    break
                case 'pending_customer_approval':
                    colour = 'warning'
                    break
                case 'submitted':
                    colour = 'primary'
                    break
                case 'confirmed':
                    colour = 'success'
                    break
                case 'paid_out':
                    colour = 'success'
                    break
                case 'cancelled':
                    colour = 'danger'
                    break
                case 'customer_approval_denied':
                    colour = 'danger'
                    break
                case 'failed':
                    colour = 'danger'
                    break
                case 'charged_back':
                    colour = 'danger'
                    break
            }
            return colour
        },
    },
}
</script>

<style scoped>
.examplex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.examplex button {
    margin: 0px !important;
}
.examplex button.btnx {
    margin-left: 10px !important;
    border-radius: 5px 0px 0px 5px;
}
.examplex button.btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
}
</style>
