<template>
    <vx-card title="People" :subtitle="cardSubTitle" :edit-action="true">
        <template slot="actions">
            <feather-icon
                @click="showEditInline"
                icon="EditIcon"
                svgClasses="h-4 w-4"
                class="mr-4"
            />
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div class="flex">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu
                    class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6"
                >
                    <vs-dropdown-item @click="newPerson"
                        >New Person</vs-dropdown-item
                    >
                    <vs-dropdown-item @click="newGroup"
                        >New Group</vs-dropdown-item
                    >
                    <vs-dropdown-item @click="newCharity"
                        >New Charity</vs-dropdown-item
                    >
                </vs-dropdown-menu>
            </vs-dropdown>
        </template>

        <vs-tabs alignment="right">
            <vs-tab label="All">
                <PeopleList
                    :people="data.people"
                    :groups="groups"
                    :charities="charities"
                    :locked="locked"
                    :edit="show.editInline"
                    cat="All"
                    @setSelectedPerson="setSelectedPerson"
                    @setSelectedGroup="setSelectedGroup"
                    @setSelectedCharity="setSelectedCharity"
                    @editPerson="editPerson"
                ></PeopleList>
            </vs-tab>
            <vs-tab label="Children">
                <PeopleList
                    :people="children"
                    :groups="futureChildrenGroup"
                    :locked="locked"
                    :personalDetails="personalDetails"
                    cat="Children"
                    @setSelectedPerson="setSelectedPerson"
                    @setSelectedGroup="setSelectedGroup"
                ></PeopleList>
            </vs-tab>
            <vs-tab label="Executors">
                <PeopleList
                    :people="executors"
                    :locked="locked"
                    cat="Executors"
                    @setSelectedPerson="setSelectedPerson"
                    @setSelectedGroup="setSelectedGroup"
                ></PeopleList>
            </vs-tab>
            <vs-tab label="Trustees">
                <PeopleList
                    :locked="locked"
                    :people="trustees"
                    cat="Trustees"
                    @setSelectedPerson="setSelectedPerson"
                    @setSelectedGroup="setSelectedGroup"
                ></PeopleList>
            </vs-tab>
            <vs-tab label="Guardians">
                <PeopleList
                    :people="guardians"
                    :locked="locked"
                    cat="Guardians"
                    @setSelectedPerson="setSelectedPerson"
                    @setSelectedGroup="setSelectedGroup"
                ></PeopleList>
            </vs-tab>
            <vs-tab label="Beneficiaries">
                <PeopleList
                    :people="beneficairies"
                    :locked="locked"
                    :groups="beneficairiesGroups"
                    :charities="beneficairiesCharities"
                    cat="Beneficairies"
                    @setSelectedPerson="setSelectedPerson"
                    @setSelectedGroup="setSelectedGroup"
                    @setSelectedCharity="setSelectedCharity"
                ></PeopleList>
            </vs-tab>
            <vs-tab label="Share">
                <PeopleList
                    :people="share"
                    :locked="locked"
                    cat="Share"
                    @setSelectedPerson="setSelectedPerson"
                    @setSelectedGroup="setSelectedGroup"
                ></PeopleList>
            </vs-tab>
            <vs-tab
                v-if="assets && assets.residential_property"
                label="Occupants"
            >
                <PeopleList
                    :people="occupants"
                    :personalDetails="personalDetails"
                    :locked="locked"
                    cat="Occupants"
                    @setSelectedPerson="setSelectedPerson"
                    @setSelectedGroup="setSelectedGroup"
                ></PeopleList>
            </vs-tab>
        </vs-tabs>
        <PersonModal
            :personId="selectedPerson"
            :show="show.personModal"
            :people="people"
            :userId="userId"
            @close="closePersonModal"
            @changed="$emit('changed')"
        ></PersonModal>
        <GroupModal
            :groupId="selectedGroup"
            :show="show.groupModal"
            :groups="groups"
            :userId="userId"
            @close="closeGroupModal"
            @changed="$emit('changed')"
        ></GroupModal>
        <CharityModal
            :charityId="selectedCharity"
            :show="show.charityModal"
            :charities="charities"
            :userId="userId"
            @close="closeCharityModal"
            @changed="$emit('changed')"
        ></CharityModal>
        <div v-show="show.editInline">
            <vs-divider></vs-divider>
            <vs-button @click="savePeople" class="mr-3">Save</vs-button>
            <vs-button @click="cancelEdit" type="border">Cancel</vs-button>
        </div>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { http } from '@/services'

import PersonModal from '../Modals/PersonModal'
import PeopleList from '../Cards/PeopleList'
import GroupModal from '../Modals/GroupModal'
import CharityModal from '../Modals/CharityModal'

export default {
    name: 'PeopleCard',
    props: [
        'people',
        'groups',
        'charities',
        'willId',
        'locked',
        'userId',
        'assets',
        'personalDetails',
        'fetchPeople',
    ],
    mixins: [textFormat],
    components: {
        PersonModal,
        GroupModal,
        CharityModal,
        PeopleList,
    },
    mounted() {
        if (this.fetchPeople) this.$emit('peopleListFetch')
    },
    watch: {
        people: {
            immediate: true,
            deep: true,
            handler(value) {
                this.data.people = [...value]
            },
        },
    },
    computed: {
        cardSubTitle() {
            if (
                (this.people && this.people.length) ||
                (this.groups && this.groups.length)
            )
                return `${this.people.length} ${
                    this.people.length == 1 ? 'Person' : 'People'
                } ${this.groups.length} ${
                    this.groups.length == 1 ? 'Group' : 'Groups'
                }`
            return 'No People'
        },
        modalTitle() {
            if (this.selectedPerson && this.selectedPerson.full_name)
                return this.selectedPerson.full_name
            return 'No Data'
        },
        children() {
            return this.people.filter(
                (person) => person.child || person.child_step
            )
        },
        executors() {
            return this.people.filter(
                (person) =>
                    person.executor_sole_primary ||
                    person.executor_primary ||
                    person.executor_secondary ||
                    person.executor_backup
            )
        },
        trustees() {
            return this.people.filter(
                (person) =>
                    person.trustee_sole_primary ||
                    person.trustee_primary ||
                    person.trustee_secondary ||
                    person.trustee_backup
            )
        },
        guardians() {
            return this.people.filter(
                (person) =>
                    person.guardian_sole_primary ||
                    person.guardian_primary ||
                    person.guardian_secondary ||
                    person.guadian_backup
            )
        },
        beneficairies() {
            return this.people.filter(
                (person) =>
                    person.distribution_sole_primary ||
                    person.distribution_primary ||
                    person.distribution_backup
            )
        },
        beneficairiesGroups() {
            return this.groups.filter(
                (group) =>
                    group.distribution_primary || group.distribution_backup
            )
        },
        beneficairiesCharities() {
            return this.charities.filter(
                (charity) =>
                    charity.distribution_primary || charity.distribution_backup
            )
        },
        futureChildrenGroup() {
            return this.groups.filter(
                (group) => group.group_name === 'Future Children'
            )
        },
        share() {
            return this.people.filter((person) => person.share)
        },
        occupants() {
            return this.people.filter(
                (person) => person.residential_property_occupant
            )
        },
    },
    data() {
        return {
            selectedPerson: null,
            selectedGroup: null,
            selectedCharity: null,
            show: {
                personModal: false,
                groupModal: false,
                charityModal: false,
                editInline: false,
            },
            data: {
                people: null,
            },
        }
    },
    methods: {
        editPerson(data) {
            var index = this.data.people.findIndex(
                (person) => person.id === data.id
            )
            if (typeof index !== 'undefined' && index >= 0) {
                this.data.people.splice(index, 1, data)
            }
        },
        cancelEdit(data) {
            this.show.editInline = false
            this.data.people = this.people
        },
        showEditInline() {
            if (!this.show.editInline) this.show.editInline = true
            else {
                this.cancelEdit()
            }
        },
        savePeople() {
            var data = this.data.people.map((person) => {
                return {
                    id: person.id,
                    full_name: person.full_name,
                }
            })
            http.patch('people_multiple_edit', data)
                .then((response) => {
                    this.$emit('peopleListFetch')
                    this.show.editInline = false
                    this.$vs.notify({
                        title: 'Success',
                        text: 'People Saved',
                        color: 'success',
                        iconPack: 'feather',
                        position: 'top-right',
                        icon: 'icon-send',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'success',
                        iconPack: 'feather',
                        position: 'top-right',
                        icon: 'icon-send',
                    })
                })
        },
        setSelectedPerson(personId) {
            this.selectedPerson = personId
            this.show.personModal = true
        },
        setSelectedGroup(groupId) {
            this.selectedGroup = groupId
            this.show.groupModal = true
        },
        setSelectedCharity(charityId) {
            this.selectedCharity = charityId
            this.show.charityModal = true
        },
        closePersonModal() {
            this.$emit('peopleListFetch')
            this.selectedPerson = null
            this.show.personModal = false
        },
        closeGroupModal() {
            this.$emit('peopleListFetch')
            this.selectedGroup = null
            this.show.groupModal = false
        },
        closeCharityModal() {
            this.$emit('peopleListFetch')
            this.selectedCharity = null
            this.show.charityModal = false
        },
        newPerson() {
            this.selectedPerson = null
            this.show.personModal = true
        },
        newGroup() {
            this.selectedGroup = null
            this.show.groupModal = true
        },
        newCharity() {
            this.selectedCharity = null
            this.show.charityModal = true
        },
    },
}
</script>
